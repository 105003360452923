<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-09 14:45:55
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-15 12:18:08
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/Warning/WarningDetalModel/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal v-model="warningDetailVisible" title="预警详情" @onCancel="handleCancel" width="1182px">
    <div slot="footer" class="footer">
      <a-button type="primary" @click="warningDetailVisible = false">确定</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <!-- 预警名称 -->
      <a-form-model-item prop="name" label="预警名称">
        <a-input :value="warningInfo.name" disabled style="width: 356px" />
      </a-form-model-item>
      <!-- 预警对象 -->
      <a-form-model-item prop="target" label="预警对象">
        <a-input :value="targetName" disabled style="width: 356px" />
      </a-form-model-item>
    </a-form-model>
    <el-table :data="dataList" style="width: 100%">
      <!-- 供应商 -->
      <el-table-column prop="publisherName" label="供应商" min-width="140">
        <div class="name-box" style="width: 130px" slot-scope="{ row }">
          <div class="name text-ellipsis">
            {{ row.publisherName || '--' }}
          </div>
          <div class="id" v-if="row.publisherName">
            <div class="id-box">{{ row.publisherId }}</div>
            <CopySvg class="copy-icon percent-text" @click="copyId(row.publisherId)" />
          </div>
        </div>
      </el-table-column>
      <!-- 广告位 -->
      <el-table-column prop="placeName" label="广告位" min-width="140">
        <div class="name-box" style="width: 130px" slot-scope="{ row }">
          <div class="name text-ellipsis">
            {{ row.placeName || '--' }}
          </div>
          <div class="id" v-if="row.placeName">
            <div class="id-box">{{ row.placeId }}</div>
            <CopySvg class="copy-icon percent-text" @click="copyId(row.placeId)" />
          </div>
        </div>
      </el-table-column>
      <!-- 广告源 -->
      <el-table-column prop="dspName" label="广告源" min-width="140">
        <div class="name-box" style="width: 130px" slot-scope="{ row }">
          <div class="name text-ellipsis">
            {{ row.dspName || '--' }}
          </div>
          <div class="id" v-if="row.dspName">
            <div class="id-box">{{ row.platPlaceId }}</div>
            <CopySvg class="copy-icon percent-text" @click="copyId(row.platPlaceId)" />
          </div>
        </div>
      </el-table-column>
      <el-table-column label="预警实际触发值" min-width="250">
        <div class="warning" slot-scope="{ row }">
          <span class="rule-name">{{ getTypeName(row.ruleId) }}</span>今天【{{ warningInfo.newDate }}】比昨天【{{ warningInfo.oldDate }}】在{{ warningInfo.hour }}时段<span :class="{'up': row.newValue > row.oldValue ,'down': row.newValue <= row.oldValue }">{{ row.newValue > row.oldValue ? '上涨' : '下跌' }}</span>{{ getRadio(row) }}；
          <div>
            今天【{{ warningInfo.newDate }}】：{{ row.newValue }}；昨天【{{ warningInfo.oldDate }}】：{{ row.oldValue }}
          </div>
        </div>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <a-button type="link" @click="skipHourReport(row)">查看更多数据</a-button>
        </template>
      </el-table-column>
    </el-table>
  </a-modal>
</template>

<script>
import { targetList } from '@/viewsForManage/FunctionList/EditWarning/BaseForm/targetList'
import CopySvg from '@/assets/icons/copy.svg?inline'
import { copy } from '@/utils/string'
import { ruleIdList } from '@/viewsForManage/FunctionList/EditWarning/ConditionForm/ruleIdList'
export default {
  components: { CopySvg },
  data () {
    return {
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 22 }
      },
      query: {},
      targetList,
      ruleIdList
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    warningInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    warningInfo: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    dataList () {
      const { children = [] } = this.warningInfo
      return children
    },
    targetName () {
      return this.targetList.find((item) => item.id === this.warningInfo.target)?.name
    },
    warningDetailVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    skipHourReport (row) {
      this.$router.push({
        name: 'hoursreport',
        query: {
          oldDate: this.warningInfo.oldDate,
          newDate: this.warningInfo.newDate
        }
      })
    },
    getRadio (row) {
      // const radio = (row.newValue - row.oldValue) / row.oldValue
      // return `${(radio / 100).toFixed(2)}%`
      return row.floatValue ? `${row.floatValue}%` : row.floatValue
    },
    getTypeName (id) {
      return this.ruleIdList.find((item) => +item.id === +id).name
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleCancel () {
      this.warningDetailVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.name-box {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .name {
    width: 100%;
    color: @compValueColor;
    font-weight: 400;
  }
  .id {
    display: flex;
    width: 100%;
    color: @compDisabledColor;
    align-items: center;
    position: relative;
    .id-box {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 20px);
    }
    .copy-icon {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
}
.warning{
  .rule-name, .up{
    color: red;
  }
  .down{
    color: green;
  }
}
</style>
