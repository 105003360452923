<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-25 16:39:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-27 18:34:25
 * @FilePath: /mediatom-web/src/components/sourceModal/HourPricingStrategyFormForAdx/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="hour-pricing-strategy-container">
    <!-- 分时段控制 -->
    <a-form-model-item prop="hourControl" label="分时段控制">
      <a-radio-group v-model="formquery.hourControl">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
      <a-button type="link" icon="plus" @click="handleAddHourItem" v-if="formquery.hourControl">增加时段</a-button>
    </a-form-model-item>
    <template v-if="formquery.hourControl">
      <div v-for="(item, index) in formquery.hourControlPriceStrategyList" :key="index">
        <a-form-model-item :label="`分时段控制${index + 1}`">
          <a-icon type="close" class="close-icon" @click="handleDeleteHourItem(index)" />
        </a-form-model-item>
        <a-form-model-item label="选择时段" :prop="`hourControlPriceStrategyList[${index}].hours`" :rules="[{ required: true, validator: validatorHours, trigger: 'change' }]">
          <el-time-picker
            :clearable="false"
            is-range
            value-format="HH:mm"
            format="HH:mm"
            @change="handleChangeHours($event, item)"
            v-model="item.hours"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围">
          </el-time-picker>
        </a-form-model-item>
        <PricingStrategyFormForAdx
          :propText="`hourControlPriceStrategyList[${index}].`"
          v-bind="$attrs"
          v-on="$listeners"
          :query="item"
        />
      </div>
    </template>
    <!-- 分界线 -->
    <div class="more-setting" v-if="formquery.hourControl">
      <a-form-model-item :wrapperCol="{ offset: 3, span: 17 }">
        <a-divider> 未配置时段 </a-divider>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
import DateMix from '@/mixins/initDate'
import PricingStrategyFormForAdx from '@/components/sourceModal/PricingStrategyFormForAdx'
export default {
  name: 'HourPricingStrategyFormForAdx',
  components: { PricingStrategyFormForAdx },
  mixins: [DateMix],
  created () {
    this.$set(this.formquery, 'hourControl', this.value.hourControl || 0)
    this.$set(this.formquery, 'hourControlPriceStrategyList', this.value.hourControlPriceStrategyList || [])
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    initHours () {
      this.formquery.hourControlPriceStrategyList.forEach((item) => {
        const startList = item.startHour.split(':')
        const startH = parseInt(startList[0])
        const startM = parseInt(startList[1])
        const endList = item.endHour.split(':')
        const endH = parseInt(endList[0])
        const endM = parseInt(endList[1])
        this.$set(item, 'hours', [new Date(null, null, null, startH, startM), new Date(null, null, null, endH, endM)])
      })
    },
    handleChangeHours (e, item) {
      item.startHour = e[0]
      item.endHour = e[1]
    },
    handleAddHourItem () {
      if (!this.formquery.hourControlPriceStrategyList) {
        this.formquery.hourControlPriceStrategyList = []
      }
      this.formquery.hourControlPriceStrategyList.push({
        startHour: '00:00',
        endHour: '01:00',
        hours: [new Date(null, null, null, 0, 0), new Date(null, null, null, 1, 0)]
      })
    },
    handleDeleteHourItem (index) {
      this.formquery.hourControlPriceStrategyList.splice(index, 1)
    },
    validatorHours (rule, value, callback) {
      let flag = true
      this.formquery.hourControlPriceStrategyList.forEach((item, index) => {
        const startMin = this.moment(item.startHour, 'HH:mm').unix()
        const endMin = this.moment(item.endHour, 'HH:mm').unix()
        this.formquery.hourControlPriceStrategyList.forEach((item2, index2) => {
          const startMin2 = this.moment(item2.startHour, 'HH:mm').unix()
          const endMin2 = this.moment(item2.endHour, 'HH:mm').unix()
          if (index === index2) {
          } else if (startMin >= endMin2 || endMin <= startMin2) {
          } else {
            flag = false
          }
        })
      })
      if (!flag) {
        return callback(new Error('时段不能重叠'))
      }
      return callback()
    }
  }
}
</script>

<style lang="less" scoped></style>
