<template>
  <div class="advertiser_container">
    <div class="sonfig_operate">
      <div class="sonfig_search">
        <m-select-more
          class="search_item"
          :showSelectedPart="true"
          :allData="platList"
          v-model="query.platIdList"
          label="广告平台"
          @change="changePlat"
        />
        <m-select-more
          class="search_item"
          :showSelectedPart="true"
          :allData="platAccountList"
          v-model="query.platAccountIdList"
          label="账户"
          @change="handleSearch"
        />
      </div>
      <div class="btn-box">
        <a-button class="add-btn" type="primary" @click="handleClickAddPlatBtn">添加广告平台</a-button>
      </div>
    </div>
    <div class="sonfigs_tables">
      <a-table
        class="m_table_cust"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :rowKey="
          (record, index) => {
            return record.id
          }
        "
        :childrenColumnName="null"
        :loading="loading"
      >
        <template slot="isOss" slot-scope="isOss">
          {{ +isOss === 0 ? '中国大陆' : '海外' }}
        </template>
        <div class="primary-text" slot="dspNum" slot-scope="dspNum, record" @click="handleSkipDsp(record)">
          {{ dspNum || 0 }}个
        </div>
        <div slot="action" class="action-slot" slot-scope="record">
          <a-popover placement="bottom" :ref="`popover${record.id}`" overlayClassName="personnel_setting_popover">
            <GroupSvg style="cursor: pointer;color:#fff;" />
            <div class="popover-btn-box" slot="content">
              <div class="popover-btn" @click="handleClickEditPlatBtn(record)"><EditSvg class="icon"/>编辑</div>
              <div class="popover-btn" @click="handleClickDeletePlatBtn(record)"><DeleteSvg class="icon"/>删除</div>
            </div>
          </a-popover>
        </div>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
      <m-empty v-if="!loading && dataList.length == 0" style="height: 341px" imgHeight="176px">
        <template #description> 暂时没有任何数据 </template>
      </m-empty>
    </div>
    <PlatModal
      :isAdd="isAdd"
      :visible="platVisible"
      v-if="platVisible"
      :platInfo="platInfo"
      @modalCancel="platVisible = false"
      :platList="platList"
      @changePlat="() => {
        getPagerList()
        getRtbPlatAccounts()
      }"
    />
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="账号"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
  </div>
</template>

<script>
import PlatModal from './components/platModal'
import getListMix from '@/mixins/getListForManage'
import { mapState } from 'vuex'
import { getPlatPager, updatePlat } from '@/apiForManage/advertisement'
import { getRtbPlatAccounts } from '@/apiForManage/common'
import actionIconMixin from '@/mixins/actionIcon'
const columns = [
  {
    title: '广告平台',
    width: 247,
    dataIndex: 'platName'
  },
  {
    title: '区域',
    dataIndex: 'isOss',
    width: 237,
    scopedSlots: { customRender: 'isOss' }
  },
  {
    title: '账号名称',
    width: 312,
    dataIndex: 'name'
  },
  {
    title: '广告源个数',
    dataIndex: 'dspNum',
    width: 251,
    scopedSlots: { customRender: 'dspNum' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    width: 80
  }
]
export default {
  name: 'AlliancesAdvertise',
  components: { PlatModal },
  mixins: [getListMix, actionIconMixin],
  data () {
    return {
      query: {
        platIdList: [],
        platAccountIdList: [],
        page: 1,
        limit: 10
      },
      platList: [],
      platAccountList: [],
      dataList: [],
      total: 0,
      columns,
      currentPlatInfo: undefined,
      isAdd: false,
      back: false,
      platVisible: false,
      delVisible: false,
      platInfo: {},
      loading: false,
      searchquery: {
        adx: false,
        ad: false
      }
    }
  },
  created () {
    this.getPlatList()
    this.getRtbPlatAccounts()
    this.getPagerList()
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      // 权限开启=1
      creative: (state) => state.user.info.creative,
      // 超管
      roleName: (state) => state.user.info.roleName,
      // 超管子账户
      adminUserId: (state) => state.user.info.adminUserId,
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  methods: {
    handleSkipDsp (record) {
      this.$router.push({
        name: 'budgetsource',
        query: {
          accountId: '' + record.id
        }
      })
    },
    // 获取广告平台账户下拉
    async getRtbPlatAccounts () {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: this.query.platIdList
      })
      this.platAccountList = data.map((item) => ({
        ...item,
        id: '' + item.id
      }))
    },
    // 添加广告平台
    handleClickAddPlatBtn () {
      this.isAdd = true
      this.platVisible = true
    },
    // 修改广告平台
    handleClickEditPlatBtn (record) {
      this.$refs[`popover${record.id}`].$children[0].sVisible = false
      this.platInfo = this.back ? this.currentPlatInfo : record
      this.currentPlatInfo = this.platInfo
      this.isAdd = false
      this.platVisible = true
    },
    handleDeleteItem () {
      updatePlat({
        ...this.platInfo,
        sts: 'D'
      }).then((res) => {
        if (res.code === 200) {
          this.delVisible = false
          this.$message.success('删除成功')
          this.handleSearch()
        }
      })
    },
    handleClickDeletePlatBtn (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.platInfo = JSON.parse(JSON.stringify(row))
      this.delVisible = true
    },
    async changePlat () {
      await this.getRtbPlatAccounts()
      await this.handleSearch()
    },
    // 搜索
    handleSearch () {
      this.query.page = 1
      this.getPagerList()
    },
    // 获取数据
    async getPagerList () {
      this.loading = true
      const resp = await getPlatPager(this.query)
      if (resp.code === 200) {
        this.dataList = resp.data.items || []
        this.total = resp.data.total
        this.loading = false
      }
    },
    handleChangePage (page) {
      this.query.page = page
      this.getPagerList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getPagerList()
    }
  }
}
</script>

<style lang="less" scoped>
.advertiser_container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .sonfig_operate {
    display: flex;
    justify-content: space-between;
    .sonfig_search {
      display: flex;
      flex-wrap: nowrap;
      .search_item {
        width: 180px;
        margin-right: @compMargin;
      }
    }
    .btn-box{
      .search-btn{
        margin-right: @compMargin;
        width: 88px;
      }
      .add-btn{
        width: 144px;
      }
    }
  }
  .sonfigs_tables {
    padding: 0 @smallMargin;
    background-color: #fff;
    width: 100%;
    margin-top: @mediumMargin;
    overflow: hidden;
    border-radius: @mediumRadius;
    box-shadow: @modelShadow;
    border: 1px solid @modelBorderColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: calc(@smallMargin - @thPadding);
    .action-slot {
      .btn {
        color: @primary-color;
        cursor: pointer;
        &.disabled {
          color: red;
          cursor: not-allowed;
        }
      }
      span {
        margin-right: 12px;
      }
    }
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
.popover-btn-box{
  display: flex;
  flex-direction: column;
  .popover-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
    .icon{
      margin-right: 6px;
      color: white;
    }
  }
}
</style>

<style lang="less">
.personnel_setting_popover{
  .ant-popover-inner-content{
    padding: 6px 12px;
  }
}
</style>
