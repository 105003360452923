<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-26 16:50:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:53:05
 * @FilePath: /viewsForManage/FunctionList/CreativeMask/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="creative-mask-container">
    <div class="search-box">
      <div class="search-left">
        <m-select
          v-model="query.type"
          label="屏蔽类型"
          class="search-item"
          :dataList="[{name: '素材替换', id: 1}, {name: '流量定向', id: 3}]"
          :showSearch="false"
          @change="handleSearch"/>
        <a-input class="search-item" v-model.trim="query.name" placeholder="请输入关键字" @change="handleDurationChange"></a-input>
      </div>
      <div class="btn">
        <a-button class="add-btn" type="primary" @click="handleAddShield">新建屏蔽规则</a-button>
      </div>
    </div>
    <div class="table-box">
      <a-spin :spinning="isLoading">
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column fixed="left" prop="name" label="规则名称" min-width="240">
          </el-table-column>
          <el-table-column fixed="left" label="屏蔽类型" min-width="126">
            <template slot-scope="{ row }">
              {{ getTypeName(row) }}
            </template>
          </el-table-column>
          <el-table-column fixed="left" label="关联供应商/广告位" min-width="249">
            <template slot-scope="{ row }">
              {{ `${row.publisherNum}个供应商，${row.placeNum}个广告位` }}
            </template>
          </el-table-column>
          <el-table-column fixed="left" label="关联广告源" min-width="140">
            <template slot-scope="{ row }">
              {{ row.dspPlaceNum !== undefined ? `${row.dspPlaceNum}个` : `--` }}
            </template>
          </el-table-column>
          <el-table-column fixed="left" label="关联包名" min-width="170">
            <template slot-scope="{ row }">
              {{ row.bundleNum !== undefined ? `${row.bundleNum}个` : `--` }}
            </template>
          </el-table-column>
          <el-table-column fixed="left" label="修改时间" min-width="200">
            <template slot-scope="{ row }">
              {{ row.updateTime || '--' }}
            </template>
          </el-table-column>
          <!-- <el-table-column fixed="left" label="修改时间" prop="updateTime" min-width="240"></el-table-column> -->
          <el-table-column fixed="left" label="操作" min-width="80" class-name="action-column">
            <template slot-scope="{ row }">
              <a-popover placement="bottom" :ref="`popover${row.id}`" overlayClassName="qps_setting_popover">
                <GroupSvg style="cursor: pointer;color:#fff;" />
                <div class="popover-btn-box" slot="content">
                  <div class="popover-btn" @click="handleEdit(row)"><EditSvg class="icon"/>编辑</div>
                  <div class="popover-btn" @click="clickDelete(row)"><DeleteSvg class="icon"/>删除</div>
                </div>
              </a-popover>
              <!-- <span class="btn" @click="handleEdit(row)"> 编辑 </span>
              <span class="btn" @click="clickDelete(row)">删除</span> -->
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="query.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
    </div>
    <m-delete-modal
      :delVisible="delVisible"
      title="替换规则"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteShield"
    />
  </div>
</template>

<script>
import { getShieldPager, changeShieldSts } from '@/apiForManage/creativeMask'
import actionIconMixin from '@/mixins/actionIcon'
export default {
  mixins: [actionIconMixin],
  data () {
    return {
      query: {
        name: undefined,
        type: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      isLoading: false,
      delVisible: false,
      deleInfo: {},
      timer: null
    }
  },
  created () {
    this.getTableList()
  },
  methods: {
    handleDurationChange () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 1000)
    },
    handleSearch () {
      this.query.page = 1
      this.getTableList()
    },
    // 点击列表删除
    clickDelete (data) {
      this.$refs[`popover${data.id}`].$children[0].sVisible = false
      this.deleInfo = data
      this.delVisible = true
    },
    handleDeleteShield () {
      const row = this.deleInfo
      changeShieldSts({ id: row.id, sts: 'D' }).then((res) => {
        if (res.code === 200) {
          this.delVisible = false
          this.$message.success('删除成功!')
          this.getTableList()
        }
      })
    },
    handleAddShield () {
      this.$router.push({
        name: 'addmaskrule'
      })
    },
    getTypeName (row) {
      return +row.type === 1 ? '素材替换' : +row.type === 2 ? '广告屏蔽' : +row.type === 3 ? '流量定向' : '--'
    },
    handleEdit (row) {
      this.$router.push({
        name: 'updatemaskrule',
        query: {
          id: row.id
        }
      })
    },
    handleChangePage (page) {
      this.query.page = page
      this.getTableList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableList()
    },
    async getTableList () {
      this.isLoading = true
      const { data = {} } = await getShieldPager(this.query)
      const { items = [], total = 0 } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.creative-mask-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: @mediumMargin;
    .search-left {
      display: flex;
      input {
        width: 220px;
      }
      .search-item{
        margin-right: @compMargin;
        width: 180px;
      }
    }
    .btn{
      display: flex;
      justify-content: flex-start;
      .search-btn{
        width: 88px;
        margin-right: @compMargin;
      }
      .add-btn{
        width: 152px;
      }
    }
  }
  .table-box {
    border-radius: @mediumRadius;
    background-color: #fff;
    padding: 0 @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    padding-top: calc(@smallMargin - @thPadding);
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
.popover-btn-box{
  display: flex;
  flex-direction: column;
  .popover-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
    .icon{
      margin-right: 6px;
      color: white;
    }
  }
}
</style>

<style lang="less">
.qps_setting_popover{
  .ant-popover-inner-content{
    padding: 6px 12px;
  }
}
</style>
