<template>
  <a-modal
    v-model="addSourceVisible"
    :title="(isAdd === 1 ? '添加' : isAdd === 2 ? '修改' : '修改') + '广告源'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="isAdd === 1 ? 940 : 900"
    dialogClass="source_modal"
  >
    <a-spin :spinning="isLoading">
      <template slot="footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <div class="source_wrapper">
        <!-- 左侧广告平台 start -->
        <div class="source_wrapper_plat" v-if="isAdd === 1">
          <div class="plat_search">
            <a-input v-model.trim="searchKey" placeholder="搜索广告平台"><SearchSvg slot="suffix" /></a-input>
          </div>
          <div class="plat_list_wrapper">
            <div class="menu_plat">
              <div class="plat-item" v-for="item in fliterPlatList" :key="item.id" @click="handleSelectPlat(item.id)">
                <a-radio :checked="currentPlat === item.id">{{ item.name }}</a-radio>
              </div>
            </div>
          </div>
        </div>
        <!-- 左侧广告平台 end -->
        <!-- 右侧内容 start -->
        <div class="source_wrapper_form" ref="formWrapper" :style="{ 'margin-left': isAdd === 1 ? '264px' : '0' }">
          <a-form-model labelAlign="left" ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
            <!-- 新增、编辑广告源 start -->
            <template v-if="isAdd < 3">
              <!-- 账户 -->
              <a-form-model-item
                v-if="isAdd === 1"
                label="账户"
                prop="platAccountId"
                ref="platAccountId"
                :rules="[{ required: true, message: '请选择账户', trigger: 'change' }]"
              >
                <div class="account-form">
                  <a-select
                    placeholder="请选择账户"
                    showSearch
                    :filter-option="filterOption"
                    v-model="formquery.platAccountId"
                    @change="changePlatAccountId"
                    style="width: 285px; margin-right: 10px"
                  >
                    <a-select-option v-for="item in accountList" :key="item.id" :value="item.id">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                  <span v-if="!+platInfo.isOss" class="add-account" @click="handleClickAddAcount">创建新账户</span>
                </div>
              </a-form-model-item>
              <a-form-model-item label="账户" v-if="isAdd === 2">
                {{ formquery.platAccountName }}
              </a-form-model-item>
              <!-- 广告平台名称 -->
              <a-form-model-item label="广告平台" v-if="isAdd === 2">
                {{ formquery.platName }}
              </a-form-model-item>
              <!-- 广告源名称 -->
              <a-form-model-item
                :rules="[{ required: true, message: '请输入广告源名称', trigger: 'blur' }]"
                label="广告源名称"
                prop="name"
                ref="name"
              >
                <a-input placeholder="请输入广告源名称" v-model.trim="formquery.name" />
              </a-form-model-item>
              <!-- 应用ID -->
              <a-form-model-item
                :rules="[
                  {
                    required: true,
                    message: `请输入${getAppIdLabel(platInfo.id) || getAppIdLabel(sourceInfo.platId)}`,
                    trigger: 'blur'
                  }
                ]"
                v-if="showAppIds(platInfo.id) || showAppIds(sourceInfo.platId)"
                prop="platAppId"
                ref="platAppId"
              >
                <m-tips
                  slot="label"
                  :content="`对应广告平台创建应用后，平台生成的${
                    getAppIdLabel(platInfo.id) || getAppIdLabel(sourceInfo.platId)
                  }。`"
                  :title="getAppIdLabel(platInfo.id) || getAppIdLabel(sourceInfo.platId)"
                  :width="200"
                ></m-tips>
                <a-input
                  v-if="allowEdit"
                  :placeholder="`请输入${getAppIdLabel(platInfo.id) || getAppIdLabel(sourceInfo.platId)}`"
                  v-model.trim="formquery.platAppId"
                />
                <div v-else>
                  <span>{{ formquery.platAppId }}</span>
                  <a-button type="link" size="small" @click="changeAllowEdit">编辑</a-button>
                </div>
                <a-alert
                  v-if="isAdd === 2 && allowEdit"
                  :message="`修改${
                    getAppIdLabel(platInfo.id) || getAppIdLabel(sourceInfo.platId)
                  }参数后，所选账号下的所有广告位配置都会同步生效！`"
                  banner
                />
              </a-form-model-item>
              <!-- 广告源ID -->
              <a-form-model-item
                :rules="[{ required: true, message: `请输入${platPlaceLabel(platInfo.id)}`, trigger: 'blur' }]"
                prop="platPlaceId"
                ref="platPlaceId"
              >
                <!-- 其他广告平台label -->
                <!-- <span slot="label">{{ platPlaceLabel(platInfo.id) }}</span> -->
                <m-tips
                  slot="label"
                  :content="`对应广告平台创建应用中创建广告位后，生成的广告位id。`"
                  :title="`${platPlaceLabel(platInfo.id)}`"
                  :width="200"
                ></m-tips>
                <a-input
                  :placeholder="`请输入${platPlaceLabel(platInfo.id)}`"
                  v-model.trim="formquery.platPlaceId"
                  :disabled="platInfo.id === 5"
                />
              </a-form-model-item>
              <!-- 广告样式 -->
              <a-form-model-item
                label="广告样式"
                ref="position"
                prop="position"
                :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
              >
                <a-select
                  placeholder="请选择广告样式"
                  v-model="formquery.position"
                  showSearch
                  :filter-option="filterOption"
                  @change="handleChangePosition"
                >
                  <a-select-option
                    v-for="item in platPositionList"
                    :key="item.id"
                    :value="item.id"
                    showSearch
                    :filter-option="filterOption"
                  >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <!-- appKey -->
              <a-form-model-item
                v-if="showAppKey(platInfo.id)"
                label="appKey"
                prop="appKey"
                ref="appKey"
                :rules="[{ required: true, message: '请输入appKey', trigger: 'blur' }]"
              >
                <a-input placeholder="请输入appKey" v-model.trim="formquery.appKey" />
              </a-form-model-item>
              <SlotIdForm :query="formquery" :platId="+platInfo.id" :positionId="+formquery.position"></SlotIdForm>
              <template v-if="+platInfo.id === 1061">
                <a-form-model-item label="百青藤模板">
                  <a-button type="link" icon="plus" @click="addTemplate">添加</a-button>
                </a-form-model-item>
                <div class="template1061" v-for="(radio, index) in formquery.templateRadioList" :key="index">
                  <div class="title">
                    <div class="text">{{ `百青藤模板${(index + 1).toString().padStart(2, '0')}` }}</div>
                    <a-icon type="close" class="icon" @click="handleDeleteItem1061(index)"></a-icon>
                  </div>
                  <a-form-model-item
                    label="Template"
                    :prop="`templateRadioList[${index}].templateSlotId`"
                    :ref="`templateRadioList[${index}].templateSlotId`"
                    :rules="[{ required: true, message: '请选择Template', trigger: 'change' }]"
                  >
                    <a-select placeholder="请选择Template" v-model="radio.templateSlotId">
                      <a-select-option value="SIMG">小图模板，适用于左图右文或右图左文</a-select-option>
                      <a-select-option value="BIMG">大图模板</a-select-option>
                      <a-select-option value="GIMG">组图模板，目前仅支持 3 图</a-select-option>
                      <a-select-option value="VID">视频模板</a-select-option>
                      <a-select-option value="RVID">激励视频模板</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item
                    label="AspectRatio"
                    :prop="`templateRadioList[${index}].templateRadioList`"
                    :ref="`templateRadioList[${index}].templateRadioList`"
                    :rules="[{ required: true, message: '请选择AspectRatio', trigger: 'change' }]"
                  >
                    <a-select mode="multiple" placeholder="请选择AspectRatio" v-model="radio.templateRadioList">
                      <a-select-option value="RATIO_2X1">物料比例 2:1</a-select-option>
                      <a-select-option value="RATIO_3X2">物料比例 3:2</a-select-option>
                      <a-select-option value="RATIO_2X3">物料比例 2:3</a-select-option>
                      <a-select-option value="RATIO_16X9">物料比例 16:9</a-select-option>
                      <a-select-option value="RATIO_9X16">物料比例 9:16</a-select-option>
                      <a-select-option value="RATIO_1X1">物料比例 1:1</a-select-option>
                      <a-select-option value="RATIO_4X3">物料比例 4:3</a-select-option>
                      <a-select-option value="RATIO_3X1">物料比例 3:1</a-select-option>
                    </a-select>
                    <div class="AspectRatio"></div>
                  </a-form-model-item>
                </div>
              </template>
              <!-- 跃盟 -->
              <a-form-model-item
                v-if="+platInfo.id === 1080"
                label="广告位样式ID"
                prop="slotIdList"
                ref="slotIdList"
                :rules="[{ required: true, message: '请选择广告位样式ID', trigger: 'change' }]"
              >
                <m-select-more
                  v-if="+platInfo.id === 1080"
                  style="width: 100%"
                  v-model="formquery.slotIdList"
                  :allData="slotList1080"
                  :searchById="false"
                  :hasIcon="false"
                  :showId="false"
                  label="广告位样式ID"
                  :showSelectedPart="true"
                  :hasSearch="true"
                  width="450px"
                  :canSelectAll="true"
                  :showLabel="false"
                />
              </a-form-model-item>
              <!-- 展示类型 软告 1014  展示 -->
              <a-form-model-item
                label="展示类型"
                ref="type"
                prop="type"
                v-if="+platInfo.id === 1014"
                :rules="[{ required: true, message: '请输入展示类型', trigger: 'blur' }]"
              >
                <a-select placeholder="请选择展示类型" v-model="formquery.type">
                  <a-select-option v-for="item in typeList" :key="item.id" :value="+item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 出价类型 -->
              <a-form-model-item prop="settleType">
                <span slot="label">出价类型</span>
                <a-radio-group name="radioGroup" v-model="formquery.settleType">
                  <a-radio :value="1"> RTB竞价 </a-radio>
                  <a-radio :value="0"> 固价 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 分成比例 -->
              <a-form-model-item v-if="formquery.platAccountId">
                <m-tips
                  slot="label"
                  content="在账户管理-对应平台账户-编辑中进行设置"
                  title="分成比例"
                  :width="200"
                ></m-tips>
                <a-input :value="settleRatio" disabled>
                  <span slot="suffix">%</span>
                </a-input>
              </a-form-model-item>
              <AppReportedForm
                ref="appReportedForm"
                v-if="!+platInfo.isOss"
                v-model="formquery"
                :appnameReplaceList="appnameReplaceList"
                :versionReplaceList="versionReplaceList"
                :bundleReplaceList="bundleReplaceList"
              />
              <!-- 是否自动创建 -->
              <a-form-model-item
                v-if="+accountInfo?.isAutoCreate === 1 && +platInfo.id === 1061"
                prop="isAutoCreate"
                label="是否自动创建"
              >
                <a-radio-group name="radioGroup" v-model="formquery.isAutoCreate">
                  <a-radio :value="1"> 是 </a-radio>
                  <a-radio :value="0"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                label="支持系统"
                :rules="[{ required: true, message: '请选择支持系统', trigger: 'change' }]"
                ref="supportOsList"
                prop="supportOsList"
              >
                <a-checkbox
                  style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                  :checked="osCheckedAll"
                  :indeterminate="osIndeterminate"
                  @click="handleCheckedOsAll"
                >全选</a-checkbox
                >
                <a-checkbox-group v-model="formquery.supportOsList" style="display: inline">
                  <a-checkbox value="1">Android</a-checkbox>
                  <a-checkbox value="0">IOS</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
              <a-form-model-item
                v-if="!+platInfo.isOss"
                label="流量样式"
                :rules="[{ required: true, message: '请选择流量样式', trigger: 'change' }]"
                ref="supportPositionList"
                prop="supportPositionList"
              >
                <a-checkbox
                  style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                  :checked="supportPositionListCheckedAll"
                  :indeterminate="supportPositionListIndeterminate"
                  @click="handleCheckedPositionAll"
                >全选</a-checkbox
                >
                <a-checkbox-group style="display: inline" v-model="formquery.supportPositionList">
                  <a-checkbox
                    style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                    v-for="item in positionList"
                    :key="item.id"
                    :value="'' + item.id"
                  >{{ item.name }}</a-checkbox
                  >
                </a-checkbox-group>
              </a-form-model-item>
              <a-form-model-item
                v-if="+platInfo.id === 1123"
                label="支持流量类型"
                ref="supportImageList"
                prop="supportImageList"
              >
                <a-checkbox
                  style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                  :checked="supportImageListCheckedAll"
                  :indeterminate="supportImageListIndeterminate"
                  @click="handleCheckedImageAll"
                >全选</a-checkbox
                >
                <a-checkbox-group style="display: inline" v-model="formquery.supportImageList">
                  <a-checkbox
                    style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                    value="1"
                  >图片</a-checkbox
                  >
                  <a-checkbox
                    style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                    value="2"
                  >图文</a-checkbox
                  >
                  <a-checkbox
                    style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                    value="3"
                  >视频</a-checkbox
                  >
                  <a-checkbox
                    style="margin-right: 6px; margin-left: 0; margin-bottom: 14px"
                    value="4"
                  >视频+封面</a-checkbox
                  >
                </a-checkbox-group>
              </a-form-model-item>
              <!-- 广告位宽高 -->
              <a-form-model-item
                ref="width"
                prop="width"
                v-if="showHWIds(platInfo.id)"
                label="广告位宽度"
                :rules="[{ required: true, message: '请输入宽度', trigger: 'blur' }]"
              >
                <a-input-number
                  style="width: 100%; border-radius: 5px; height: 36px; line-height: 36px"
                  :min="1"
                  placeholder="请输入宽度"
                  v-model.trim="formquery.width"
                />
              </a-form-model-item>
              <!-- 广告位宽高 -->
              <a-form-model-item
                ref="height"
                prop="height"
                label="广告位高度"
                v-if="showHWIds(platInfo.id)"
                :rules="[{ required: true, message: '请输入高度', trigger: 'blur' }]"
              >
                <a-input-number
                  style="width: 100%; border-radius: 5px; height: 36px; line-height: 36px"
                  :min="1"
                  placeholder="请输入高度"
                  v-model.trim="formquery.height"
                />
              </a-form-model-item>
              <!-- 是否透传tagid -->
              <a-form-model-item v-if="+platInfo.isOss" label="是否透传tagid" prop="useTagid">
                <a-radio-group name="radioGroup" v-model="formquery.useTagid">
                  <a-radio :value="1"> 是 </a-radio>
                  <a-radio :value="0"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <template v-if="+isAdmin !== 2">
                <a-form-model-item :wrapperCol="{ span: 24 }">
                  <a-divider>高级配置</a-divider>
                </a-form-model-item>
                <a-form-model-item prop="equipmentRuleContent" label="设备">
                  <div class="flex-box">
                    <div class="flex-left">
                      <a-select placeholder="请选择类型" v-model="formquery.equipmentRuleType">
                        <a-select-option v-for="item in ruleTypeList" :key="item.value" :value="item.value">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="flex-right">
                      <m-select-more
                        style="width: 100%"
                        v-model="formquery.equipmentRuleContent"
                        :allData="brandList"
                        :searchById="false"
                        :hasIcon="false"
                        :showId="false"
                        label="设备品牌"
                        :showLabel="false"
                      />
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item prop="installRuleContent" label="安装列表">
                  <div class="flex-box">
                    <div class="flex-left">
                      <a-select placeholder="请选择类型" v-model="formquery.installRuleType">
                        <a-select-option :value="0"> 已安装 </a-select-option>
                        <a-select-option :value="1"> 未安装 </a-select-option>
                      </a-select>
                    </div>
                    <div class="flex-right">
                      <m-select-more
                        style="width: 100%"
                        v-model="formquery.installRuleContent"
                        :allData="installAppList"
                        :searchById="false"
                        :hasIcon="false"
                        :showId="false"
                        label="安装列表"
                        :showLabel="false"
                      />
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item prop="countryRuleContent" label="国家/地区">
                  <div class="flex-box">
                    <div class="flex-left">
                      <a-select placeholder="请选择类型" v-model="formquery.countryRuleType">
                        <a-select-option v-for="item in ruleTypeList" :key="item.value" :value="item.value">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="flex-right">
                      <m-select-more
                        style="width: 100%"
                        v-model="formquery.countryRuleContent"
                        :allData="countryList"
                        :searchById="false"
                        :hasIcon="false"
                        :showId="false"
                        label="国家/地区"
                        :showLabel="false"
                      />
                    </div>
                  </div>
                </a-form-model-item>
                <template v-if="showProvince">
                  <a-form-model-item prop="provinceRuleContent" label="省份">
                    <div class="flex-box">
                      <div class="flex-left">
                        <a-select placeholder="请选择类型" v-model="formquery.provinceRuleType">
                          <a-select-option v-for="item in ruleTypeList" :key="item.value" :value="item.value">
                            {{ item.label }}
                          </a-select-option>
                        </a-select>
                      </div>
                      <div class="flex-right">
                        <el-cascader
                          style="width: 100%"
                          v-model="formquery.provinceRuleContent"
                          placeholder="请选择地区"
                          collapse-tags
                          :options="provinceList"
                          :props="{
                            multiple: true,
                            value: 'code',
                            label: 'nameCn',
                            children: 'childrenList'
                          }"
                        />
                      </div>
                    </div>
                  </a-form-model-item>
                </template>
                <a-form-model-item prop="bundleRuleContent">
                  <template slot="label">
                    <span>包名</span>
                    <span style="font-size: 13px; color: #858fa1">(已添加{{ formquery.bundleRuleContent.length || 0 }}个)</span>
                  </template>
                  <div class="flex-box top">
                    <div class="flex-left">
                      <a-select placeholder="请选择类型" v-model="formquery.bundleRuleType">
                        <a-select-option v-for="item in ruleTypeList" :key="item.value" :value="item.value">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="flex-right">
                      <m-string-list
                        style="width: 100%"
                        v-model="formquery.bundleRuleContent"
                        placeholder="请输入包名，多个包名请以英文逗号分隔"
                      ></m-string-list>
                    </div>
                  </div>
                </a-form-model-item>
                <!-- 点击率控制 -->
                <a-form-model-item prop="clickControl" label="点击率控制">
                  <a-switch
                    size="small"
                    :checked="formquery.clickControl === 1"
                    @click="handleChangeSwitch('clickControl')"
                  ></a-switch>
                </a-form-model-item>
                <a-form-model-item label="点击率范围" v-if="+formquery.clickControl === 1">
                  <div class="click-rate-box">
                    <div class="input">
                      <a-form-model-item
                        prop="clickRateMin"
                        ref="clickRateMin"
                        :rules="[
                          {
                            required: true,
                            validator: handleValidatorClickRate,
                            trigger: 'blur'
                          }
                        ]"
                      >
                        <a-input
                          type="number"
                          :min="0"
                          :max="100"
                          v-model.number.trim="formquery.clickRateMin"
                          placeholder="min"
                        >
                          <span class="percent-text" slot="suffix">%</span>
                        </a-input>
                      </a-form-model-item>
                    </div>
                    <span style="margin-left: 10px"></span>
                    <div class="input">
                      <a-form-model-item
                        prop="clickRateMin"
                        ref="clickRateMin"
                        :rules="[
                          {
                            required: true,
                            validator: handleValidatorClickRate,
                            trigger: 'blur'
                          }
                        ]"
                      >
                        <a-input
                          type="number"
                          :min="0"
                          :max="100"
                          v-model.number.trim="formquery.clickRateMax"
                          placeholder="max"
                        >
                          <span class="percent-text" slot="suffix">%</span>
                        </a-input>
                      </a-form-model-item>
                    </div>
                  </div>
                </a-form-model-item>
              </template>
            </template>
            <!-- 新增、编辑广告源 end -->
          </a-form-model>
        </div>
        <!-- 右侧内容 end -->
      </div>
    </a-spin>
    <AddAccountModal
      v-bind="$attrs"
      v-if="addAccountVisible"
      :visible="addAccountVisible"
      :platInfo="platInfo"
      :accountInfo="accountInfo"
      :isAddAcount="isAddAcount"
      @modalCancel="addAccountVisible = false"
      :query="query"
      @addNewAccount="getRtbPlatAccounts(2, true)"
      @editAccount="getRtbPlatAccounts(2)"
    />
  </a-modal>
</template>

<script>
import {
  addRtb,
  updateRtb,
  getPlatPosition,
  replacerInfo,
  getApiPlatformList
} from '@/apiForManage/aggregate'
import { getProvinceList, getRtbPlatAccounts } from '@/apiForManage/common'
import SearchSvg from '@/assets/icons/search.svg?inline'
import AddAccountModal from './addAccountModal'
import { mapState } from 'vuex'
import { adStyleList, typeList, slotList1080 } from './list'
import { platPlaceLabel, showHWIds, showAppIds, getAppIdLabel, showAppKey } from '@/utils/autoLabel'
import md5 from 'js-md5'
import getList from '@/mixins/getListForManage'
import SlotIdForm from '@/components/sourceModal/SlotIdForm'
import AppReportedForm from './AppReportedForm'
export default {
  name: 'SourceModal',
  components: { AddAccountModal, SearchSvg, SlotIdForm, AppReportedForm },
  mixins: [getList],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 17 }
      },
      // 账户绑定参数
      // 广告源参数
      formquery: {},
      formqueryTemp: {
        segmentRule: [],
        platId: '',
        platAccountId: undefined,
        platAppId: '',
        name: '',
        platPlaceId: undefined,
        settleType: 1,
        equipmentRuleType: 0,
        countryRuleType: 0,
        provinceRuleType: 0,
        equipmentRuleContent: [],
        installRuleType: 0,
        installRuleContent: [],
        countryRuleContent: [],
        provinceRuleContent: [],
        bundleRuleType: 0,
        bundleRuleContent: [],
        clickControl: 0,
        clickRateMin: undefined,
        clickRateMax: undefined,
        supportPositionList: [],
        supportImageList: [],
        supportOsList: [],
        slotIdList: [],
        isAutoCreate: 0,
        templateRadioList: [
          {
            templateRadioList: [],
            templateSlotId: undefined
          }
        ],
        height: undefined,
        width: undefined,
        useTagid: 0
      },
      adStyleList,
      typeList,
      slotList1080,
      rules: {},
      rule: {},
      // 广告平台列表
      platList: [],
      // 当前广告平台（绑定左侧menu）
      currentPlat: undefined,
      // 左侧menu搜索字段
      searchKey: '',
      // 当前选择的广告平台
      platInfo: {
        id: '',
        accessType: ''
      },
      platPositionList: [],
      // 账户列表
      accountList: [],
      // 添加账户弹窗
      addAccountVisible: false,
      isAddAcount: true,
      hide: true,
      hasGetTemplateList: false,
      // 应用ID是否正在修改
      allowEdit: false,
      // 修改之前的应用ID
      // editPlatAppId: '',
      isLoading: false,
      ruleTypeList: [
        {
          installLabel: '已安装',
          label: '包括',
          value: 0
        },
        {
          installLabel: '未安装',
          label: '不包括',
          value: 1
        }
      ],
      appnameReplaceList: [],
      versionReplaceList: [],
      bundleReplaceList: [],
      // 省份地区列表
      provinceList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      // 1: 新增；2：编辑；3：配置,4: 批量配置
      type: Number,
      default: 0
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    hideInfo: {
      default: () => [],
      type: Array
    },
    brandList: {
      default: () => [],
      type: Array
    },
    countryList: {
      default: () => [],
      type: Array
    },
    installAppList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (!val) {
          return
        }
        // 编辑状态下初始化广告源参数
        if (this.isAdd === 1) {
          this.formquery = JSON.parse(JSON.stringify({ ...this.formqueryTemp }))
          this.allowEdit = true
        } else {
          this.formquery = JSON.parse(JSON.stringify({ ...this.formqueryTemp, ...this.sourceInfo }))
          this.initRule()
        }
      },
      deep: true,
      immediate: true
    },
    // 应用id编辑不允许输入汉字和空格
    'formquery.platAppId': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.platAppId = newValue.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
      }
    },
    isAdd: {
      handler (val) {
        if (val === 4) {
          let hideInfoPosition = this.hideInfo.map((x) => x.position)
          hideInfoPosition = [...new Set(hideInfoPosition)]
          if (!this.hideInfo.some((it) => it.accessType === 0)) {
            this.hide = false
          } else if (
            this.hideInfo.some((it) => it.accessType === 0) &&
            this.hideInfo.length >= 2 &&
            // 去重后样式的长度大于1表示样式不唯一
            hideInfoPosition.length > 1
          ) {
            this.hide = false
          }
          !this.hide && this.initDefault()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    osCheckedAll () {
      return this.formquery.supportOsList.length === 2
    },
    osIndeterminate () {
      return this.formquery.supportOsList.length > 0 && this.formquery.supportOsList.length < 2
    },
    supportPositionListIndeterminate () {
      return (
        this.formquery.supportPositionList.length < this.positionList.length &&
        this.formquery.supportPositionList.length > 0
      )
    },
    supportImageListIndeterminate () {
      return (
        this.formquery.supportImageList.length < 4 &&
        this.formquery.supportImageList.length > 0
      )
    },
    supportPositionListCheckedAll () {
      return this.formquery.supportPositionList.length === this.positionList.length
    },
    supportImageListCheckedAll () {
      return this.formquery.supportImageList.length === 4
    },
    settleRatio () {
      if (!this.formquery.platAccountId) {
        return ''
      }
      const account = this.accountList.find((item) => '' + item.id === '' + this.formquery.platAccountId)
      if (!account) {
        return ''
      }
      return account.settleRatio
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      sensitivity: (state) => state.asyncPermission.sensitivity,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      isAdmin: (state) => state.user.isAdmin
    }),
    showAdType () {
      const show = +this.formquery.position === 10
      return show
    },
    accountInfo () {
      const account = this.accountList.find((item) => item.id === this.formquery.platAccountId)
      return account || null
    },
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    // 搜索、显示的平台列表
    fliterPlatList () {
      return this.platList.filter((item) => item.name.toLowerCase().includes(this.searchKey.toLowerCase()))
    },
    showDrawBtnPos () {
      /**
       * 展示条件：
       * 插屏 && 广告平台为API
       * */
      const show = +this.formquery.position === 3
      return show
    },
    showProvince () {
      const countryRuleContent = this.formquery.countryRuleContent || []
      return countryRuleContent.includes('CHN')
    }
  },
  async mounted () {
    await this.platformLinkList()
    if (this.isAdd === 2) {
      this.platInfo = this.platList.find((item) => item.id === this.formquery.platId)
      this.getRtbPlatAccounts(2)
    }
    this.dealPlatPlaceAndPlatApp()
    this.getPlatPosition()
    this.getProvinceList()
    this.getPositionList()
  },
  methods: {
    handleValidatorClickRate (rule, value, callback) {
      if (+value < 0) {
        callback(new Error('请输入大于等于0的数字'))
      } else {
        callback()
      }
    },
    handleChangeSwitch (key) {
      this.formquery[key] = +!this.formquery[key]
    },
    handleCheckedOsAll () {
      if (this.osCheckedAll) {
        this.formquery.supportOsList = []
      } else {
        this.formquery.supportOsList = ['0', '1']
      }
    },
    handleCheckedPositionAll () {
      if (this.supportPositionListCheckedAll) {
        this.formquery.supportPositionList = []
      } else {
        this.formquery.supportPositionList = this.positionList.map((item) => '' + item.id)
      }
    },
    handleCheckedImageAll () {
      if (this.supportImageListCheckedAll) {
        this.formquery.supportImageList = []
      } else {
        this.formquery.supportImageList = ['1', '2', '3', '4']
      }
    },
    handleDeleteItem1061 (index) {
      this.formquery.templateRadioList.splice(index, 1)
      this.$message.success('删除成功！')
    },
    addTemplate () {
      if (!this.formquery.templateRadioList) {
        this.formquery.templateRadioList = []
      }
      this.formquery.templateRadioList.push({
        templateRadioList: [],
        templateSlotId: undefined
      })
      this.$message.success('添加成功')
    },
    // 获取国家省份列表
    async getProvinceList () {
      const { data = [] } = await getProvinceList()
      this.provinceList = data
    },
    showAppIds,
    getAppIdLabel,
    showAppKey,
    async getReplacerInfo () {
      const { data = {} } = await replacerInfo({
        platAccountId: this.formquery.platAccountId
      })
      const { bundleReplaceList = [], versionReplaceList = [], appnameReplaceList = [] } = data
      this.bundleReplaceList = bundleReplaceList
      this.versionReplaceList = versionReplaceList
      this.appnameReplaceList = appnameReplaceList
    },
    handleChangePosition (e) {
      const positionName = this.platPositionList.find((item) => +item.id === +e).name
      if (positionName.includes('开屏') || positionName.includes('开机')) {
        // 开屏
        this.formquery.width = 1080
        this.formquery.height = 1920
      } else if (positionName.includes('插屏')) {
        // 插屏
        this.formquery.width = 1080
        this.formquery.height = 1920
      } else if (positionName.includes('激励视频')) {
        this.formquery.width = 720
        this.formquery.height = 1280
      } else if (positionName.includes('banner') || positionName.includes('横幅')) {
        this.formquery.width = 640
        this.formquery.height = 100
      } else {
        this.formquery.width = 1280
        this.formquery.height = 720
      }
    },
    changeFormQuery (e) {
      this.formquery = JSON.parse(JSON.stringify(e))
    },

    getRuleQuery () {
      this.formquery.segmentRule = []
      // 设备
      if (this.formquery.equipmentRuleContent.length) {
        this.formquery.segmentRule.push({
          ruleId: 7,
          ruleContent: this.formquery.equipmentRuleContent,
          ruleType: this.formquery.equipmentRuleType
        })
      }
      // 安装列表
      if (this.formquery.installRuleContent.length) {
        this.formquery.segmentRule.push({
          ruleId: 15,
          ruleContent: this.formquery.installRuleContent,
          ruleType: this.formquery.countryRuleType
        })
      }
      // 国家
      if (this.formquery.countryRuleContent?.length) {
        this.formquery.segmentRule.push({
          ruleId: 16,
          ruleContent: this.formquery.countryRuleContent,
          ruleType: this.formquery.countryRuleType
        })
      }
      // 省份
      if (
        this.formquery.provinceRuleContent &&
        this.formquery.provinceRuleContent.length &&
        this.formquery.countryRuleContent?.includes('CHN')
      ) {
        this.formquery.segmentRule.push({
          ruleId: 4,
          ruleContent: this.formquery.provinceRuleContent.map((item) => item.join('-')),
          ruleType: this.formquery.provinceRuleType
        })
      }
      // 包名
      if (this.formquery.bundleRuleContent?.length) {
        this.formquery.segmentRule.push({
          ruleId: 17,
          ruleContent: this.formquery.bundleRuleContent,
          ruleType: this.formquery.bundleRuleType
        })
      }
    },
    initRule () {
      // 设备
      this.$set(this.formquery, 'equipmentRuleContent', [])
      this.$set(this.formquery, 'equipmentRuleType', 0)
      // 安装列表
      this.$set(this.formquery, 'installRuleContent', [])
      this.$set(this.formquery, 'installRuleType', 0)
      // 国家
      this.$set(this.formquery, 'countryRuleContent', [])
      this.$set(this.formquery, 'countryRuleType', 0)
      // 省份
      this.$set(this.formquery, 'provinceRuleContent', [])
      this.$set(this.formquery, 'provinceRuleType', 0)
      // 包名
      this.$set(this.formquery, 'bundleRuleContent', [])
      this.$set(this.formquery, 'bundleRuleType', 0)
      ;(this.sourceInfo.segmentRule || []).forEach((item) => {
        // 设备
        if (+item.ruleId === 7) {
          this.$set(this.formquery, 'equipmentRuleContent', item.ruleContent)
          this.$set(this.formquery, 'equipmentRuleType', +item.ruleType || 0)
        }
        // 安装列表
        if (+item.ruleId === 15) {
          this.$set(this.formquery, 'installRuleContent', item.ruleContent)
          this.$set(this.formquery, 'installRuleType', +item.ruleType || 0)
        }
        // 国家
        if (+item.ruleId === 16) {
          this.$set(this.formquery, 'countryRuleContent', item.ruleContent)
          this.$set(this.formquery, 'countryRuleType', +item.ruleType || 0)
        }
        // 省份
        if (+item.ruleId === 4) {
          this.$set(
            this.formquery,
            'provinceRuleContent',
            (item.ruleContent || []).map((item) => item.split('-'))
          )
          this.$set(this.formquery, 'provinceRuleType', +item.ruleType || 0)
        }
        // 包名
        if (+item.ruleId === 17) {
          this.$set(this.formquery, 'bundleRuleContent', item.ruleContent || [])
          this.$set(this.formquery, 'bundleRuleType', +item.ruleType || 0)
        }
      })
    },
    initDefault () {
      this.hideInfo.forEach((item, index) => {
        if (item.position === '1') {
          // 开屏 广告交互 开启
          item.hotspot = 'A'
          // 灵敏度  50
          item.sensitivity = 50
        } else if (item.position === '2') {
        } else if (item.position === '3') {
          // 插屏 关闭按钮控制    一直显示
          item.closeButtonType = 'A'
          // 关闭按钮大小    50%
          item.buttonSize = 50
          // 插屏样式        竖屏  横屏  (两个都要，复选)
          item.drawTypeList = ['0', '1']
          // 关闭按钮位置    浮图右上
          item.buttonPosition = '0'
          // 广告交互        关闭
          item.hotspot = 'S'
        } else if (item.position === '4') {
          // 原生自渲染
        } else if (item.position === '5') {
          // 激励视频
        } else if (item.position === '6') {
          // 原生模板
          // 模板类型      图文混合
          // item.templateTypeId = 0
          // 左右边距           0
          item.lrMargin = 0
          // 上下边距           0
          item.tbMargin = 0
        } else if (item.position === '7') {
          // 全屏视频
        } else if (item.position === '8') {
          // Draw视频
        } else if (item.position === '10') {
          // 原生混合
          // 渲染方式       自渲染
          item.adStyle = 2
        }
      })
    },
    handleClickAddAcount () {
      this.isAddAcount = true
      this.addAccountVisible = true
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    platPlaceLabel,
    showHWIds,
    // 应用ID编辑
    changeAllowEdit () {
      this.allowEdit = true
      // 保存原始应用ID
      // this.editPlatAppId = this.formquery.platAppId
    },
    // 获取账户列表
    /**
     * type 默认为1，即需要账户id选中第一个，传入其它值则不会默认选择第一项（编辑模式）
     * @param {*} type
     */
    async getRtbPlatAccounts (type = 1, addsuccess = false) {
      const resp = await getRtbPlatAccounts({
        platIdList: [this.formquery.platId]
      })
      this.accountList = resp.data || []
      if (type === 1) {
        this.accountList.length > 0 && (this.formquery.platAccountId = this.accountList[0].id)
      } else if (addsuccess) {
        this.formquery.platAccountId = this.accountList[this.accountList.length - 1].id
      }
      // 获取完账号后，获取该账号下对应的上传包信息列表
      this.getReplacerInfo()
    },
    // 广告主样式
    async getPlatPosition () {
      const { data = [] } = await getPlatPosition({
        platId: this.formquery.platId
      })
      // data.forEach((item) => {
      //   item.id = +item.id
      // })
      this.platPositionList = data
    },
    // 选中广告平台
    handleSelectPlat (id) {
      this.$refs.ruleForm.clearValidate()
      // 当前广告平台ID
      this.currentPlat = id
      this.formquery.platId = id
      this.$set(this.formquery, 'platPlaceId', undefined)
      this.$set(this.formquery, 'platAccountId', undefined)
      this.$set(this.formquery, 'slotId', undefined)
      this.$set(this.formquery, 'slotIdList', [])
      this.getPlatPosition()
      // 保存当前广告平台信息
      this.platInfo = this.platList.find((item) => item.id === id)
      // 获取当前广告平台的账号
      this.getRtbPlatAccounts().then(() => {
        this.changePlatAccountId(this.formquery.platAccountId)
      })
      // 仓渊ADX、自定义广告平台的广告源ID 应用ID随机生成
      if (id === 5) {
        this.formquery.platPlaceId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
        this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      } else {
        this.formquery.platPlaceId = undefined
        this.formquery.platAppId = undefined
      }
      // 爱奇艺、拼多多、 同程、瑞狮自动生成应用ID
      if (id === 1001 || id === 1027 || id === 1024 || id === 1031) {
        this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      }
    },
    // 获取广告平台列表
    async platformLinkList () {
      this.isLoading = true
      const { data = [] } = await getApiPlatformList()
      this.platList = data
      // 添加模式选中第一项并获取当前广告平台下的账户
      if (this.isAdd === 1) {
        // 使用第一个广告平台
        this.platInfo = this.platList[0]
        this.currentPlat = this.platInfo.id
        this.formquery.platId = this.platInfo?.id
      }
      this.isLoading = false
    },
    dealPlatPlaceAndPlatApp () {
      if (this.isAdd !== 1) {
        return
      }
      // 仓渊ADX、自定义广告平台的广告源ID 应用ID随机生成
      if (+this.platInfo.id === 5 || +this.platInfo.accessType === 2) {
        this.$set(this.formquery, 'platPlaceId', md5('' + new Date().getTime() + Math.random()).slice(0, 10))
        this.$set(this.formquery, 'platAppId', md5('' + new Date().getTime() + Math.random()).slice(0, 10))
      } else {
        this.$set(this.formquery, 'platPlaceId', undefined)
        this.$set(this.formquery, 'platAppId', undefined)
      }
      // 爱奇艺、拼多多、同程、瑞狮自动生成应用ID
      if (
        +this.platInfo.id === 1001 ||
        +this.platInfo.id === 1027 ||
        +this.platInfo.id === 1024 ||
        +this.platInfo.id === 1031
      ) {
        this.$set(this.formquery, 'platAppId', md5('' + new Date().getTime() + Math.random()).slice(0, 10))
      }
      this.getRtbPlatAccounts().then(() => {
        this.changePlatAccountId(this.formquery.platAccountId)
      })
    },
    handleCancel () {
      this.addSourceVisible = false
    },
    // 确认按钮点击事件
    async handleSubmit () {
      if (this.isAdd < 3) {
        this.$refs.ruleForm.validate((validate, unValidateObj) => {
          if (!validate) {
            this.scrollToUnvalidate(unValidateObj)
            return false
          }
          this.handleAddOrEdit()
        })
      } else {
        this.$refs.ruleForms.validate((validate, unValidateObj) => {
          if (!validate) {
            this.scrollToUnvalidate(unValidateObj)
            return false
          }
          this.handleAddOrEdit()
        })
      }
    },
    scrollToUnvalidate (unValidateObj) {
      const keys = Object.keys(unValidateObj)
      this.$refs[keys[0]].$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    },
    // 修改账户事件
    changePlatAccountId (e) {
      const account = this.accountList.find((item) => item.id === e)
      // 仓渊ADX、拼多多、同程、爱奇艺、瑞狮修改账户时，由于有默认生成的应用ID，应用ID不需要变化
      if (account && account.platAppId) {
        this.formquery.platAppId = account.platAppId
        this.allowEdit = false
      } else if (
        this.platInfo.id !== 5 &&
        this.platInfo.id !== 1001 &&
        this.platInfo.id !== 1027 &&
        this.platInfo.id !== 1024 &&
        this.platInfo.id !== 1031 &&
        +this.platInfo.accessType !== 2
      ) {
        this.formquery.platAppId = undefined
        this.allowEdit = true
      }
      this.getReplacerInfo()
    },
    // 添加或编辑广告源
    async handleAddOrEdit () {
      if (this.$refs.appReportedForm && !this.$refs.appReportedForm.validateWeight()) {
        return
      }
      let editRes
      // 添加
      if (this.isAdd === 1) {
        this.getRuleQuery()
        const resp = await addRtb(this.formquery)
        if (resp.code === 200) {
          this.$message.success('添加成功!')
          this.$emit('changeSource')
          this.addSourceVisible = false
        } else if (resp.code === 10002) {
          this.$confirm({
            title: '提示',
            content: resp.msg,
            onOk () {},
            cancelText: '取消',
            onCancel () {}
          })
        }
      } else if (this.isAdd === 2) {
        this.getRuleQuery()
        // 编辑 若修改应用ID成功，则继续完成编辑操作
        if ((editRes && editRes.code === 200) || !editRes) {
          const resp = await updateRtb(this.formquery)
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSource')
            this.addSourceVisible = false
          }
        }
      }
    }
  }
}
</script>
<style lang="less">
.source_modal .ant-modal-body {
  padding: 0;
}
</style>

<style lang="less" scoped>
.source_wrapper {
  top: 0;
  left: 0;
  height: 60vh;
  overflow-y: hidden;
  overflow-x: hidden;
  .source_wrapper_plat {
    padding: @smallMargin;
    border-right: 1px solid @compBorderColor;
    background-color: #fff;
    width: 264px;
    position: absolute;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .plat_list_wrapper {
      flex-grow: 1;
      margin-top: 10px;
      height: 0;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #dbdee5;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #eff0f7;
      }
      .menu_plat {
        .plat-item {
          width: 100%;
          overflow: hidden;
          line-height: 40px;
        }
      }
    }
  }
  .source_wrapper_form {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    height: 100%;
    overflow-y: auto;
    padding: @smallMargin 60px;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #dbdee5;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #eff0f7;
    }
    ::v-deep {
      .el-radio.is-bordered {
        margin-right: 0px;
      }
      .el-radio.is-bordered.is-checked {
        background: fade(@primary-color, 10%);
        border-radius: 5px;
        border: 1px solid fade(@primary-color, 30%);
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: @primary-color;
      }
      .el-radio__input {
        display: none;
      }
    }
    .account-form {
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: center;
      .add-account {
        margin-left: 10px;
        color: @assisColor;
        cursor: pointer;
      }
    }
    .inputNumber {
      border-radius: 5px;
      height: 36px;
      line-height: 36px;
    }
  }
}
.leftBox {
  width: 180px;
  height: 100%;
  .icon-logo {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
    overflow: hidden;
    min-width: 42px;
    margin-right: -14px;
    img {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }
}
.ant-popover-inner-content {
  padding: 1px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.flex-box {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  &.top {
    align-items: flex-start;
  }
  .flex-left {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .flex-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.specifiec-plat-box {
  display: flex;
  padding-left: 160px;
  .prop-item {
    width: 300px;
    display: flex;
    flex-wrap: nowrap;
  }
}
.template1061 {
  position: relative;
  border-radius: 10px;
  padding: 0 10px 0 0;
  // .close{
  //   height: 30px;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //
  // }
  .title {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px 0 10px;
    justify-content: space-between;
    .icon {
      font-size: 12px;
      cursor: pointer;
      color: @compDisabledColor;
    }
  }
  .AspectRatio {
    transform: translateY(20px);
    border-bottom: 1px solid @compBorderColor;
  }
}
.click-rate-box {
  display: flex;
  justify-content: flex-start;
  .input {
    width: 200px;
  }
}
</style>
