import store from '@/store'
const title = store.getters.title
const localColumns = {
  targetArr: [{
    title: `流量数据`,
    list: [{
      label: '流量请求',
      width: 140,
      prop: 'request',
      align: 'left',
      desc: `供应商向${title}发起请求的次数。`,
      sortable: true
    },
    {
      label: '流量填充',
      width: 140,
      prop: 'response',
      align: 'left',
      desc: `供应商向${title}发送请求后，广告返回的次数。`,
      sortable: true
    },
    {
      label: '流量填充率',
      width: 120,
      prop: 'requestFilledRate',
      align: 'left',
      desc: `供应商向${title}发送请求后，响应成功的占比。`,
      sortable: true
    },
    {
      label: '流量填充耗时',
      width: 120,
      prop: 'responseTime',
      align: 'left',
      desc: `${title}收到流量请求到返回流量填充的平均耗时`,
      sortable: true
    },
    {
      label: '流量超时时间',
      width: 130,
      prop: 'tmax',
      align: 'left',
      sortable: true,
      desc: `供应商在请求${title}时传入的超时时间。`
    },
    {
      label: '流量超时率',
      width: 130,
      prop: 'adxTimeoutRate',
      align: 'left',
      sortable: true,
      desc: `${title}统计到的流量请求超时占流量请求的比例，流量超时率 = 流量超时数/流量请求数。`
    }
    ]
  },
  {
    title: '预算数据',
    list: [{
      label: '广告请求',
      width: 120,
      prop: 'advRequest',
      align: 'left',
      desc: `${title}向广告平台发起请求的次数。`,
      sortable: true
    },
    {
      label: '广告填充率',
      width: 120,
      prop: 'advRequestFilledRate',
      align: 'left',
      desc: `${title}向广告平台发送请求后，返回成功的占比。`,
      sortable: true
    },
    {
      label: '广告填充',
      width: 130,
      prop: 'advResponse',
      align: 'left',
      sortable: true,
      desc: `${title}向广告平台发送请求后返，回广告填充的个数。`
    },
    {
      label: '广告超时率',
      width: 130,
      prop: 'timeoutRate',
      align: 'left',
      sortable: true,
      desc: `${title}统计到的上游广告平台在规定时间内未成功响应广告的占比。`
    }
    ]
  },
  {
    title: '展示和点击数据',
    list: [
      {
        label: '展示',
        width: 150,
        prop: 'impress',
        align: 'left',
        desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异。`,
        sortable: true
      },
      {
        label: '展示（去重）',
        width: 150,
        prop: 'uniqImpress',
        align: 'left',
        desc: `${title}统计的广告曝光，根据请求ID去重后的次数。`,
        sortable: true
      },
      {
        label: '展示率',
        width: 120,
        prop: 'impressRate',
        align: 'left',
        desc: `${title}收到来自广告平台的广告返回后，展示成功的占比，展示率 = 展示数/填充数。`,
        sortable: true
      },
      {
        label: '展请率',
        width: 140,
        prop: 'impressRequestRate',
        align: 'left',
        desc: `${title}统计到的展示占请求数的比例，展请率 = 展示数/请求数。`,
        sortable: false
      }, {
        label: '点击',
        width: 120,
        prop: 'click',
        align: 'left',
        desc: `${title}统计的广告点击数，由于统计口径差异、网络丢包等因素，${title}统计的点击数据与广告平台点击数据可能存在差异。`,
        sortable: true
      },
      {
        label: '点击率',
        width: 120,
        prop: 'clickRate',
        align: 'left',
        desc: `${title}统计的点击率，点击率=点击数/展示数。`,
        sortable: false
      },
      {
        label: '点击(去重)',
        width: 120,
        prop: 'uniqClick',
        align: 'left',
        desc: `${title}统计的广告点击，根据请求ID去重后的次数。`,
        sortable: false
      }
    ]
  },
  {
    title: '成本和收入数据',
    list: [{
      label: '媒体消耗',
      width: 150,
      prop: 'income',
      align: 'left',
      desc: `${title}统计与供应商成交的广告总价格，即流量成本，媒体消耗= 展示成功后供应商上报的展示价格*展示次数/1000。`,
      sortable: true
    },
    {
      label: '广告收入',
      width: 150,
      prop: 'advPrice',
      align: 'left',
      desc: `${title}统计的展示后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
      sortable: true
    },
    {
      label: '收入（分成后）',
      width: 150,
      prop: 'shareIncome',
      align: 'left',
      desc: `${title}统计的与广告平台分成后的收入，收入（分成后）= 广告收入*（1-分成比例）`,
      sortable: true
    },
    {
      label: '利润',
      width: 120,
      prop: 'profit',
      align: 'left',
      desc: `${title}统计的利润，利润=广告收入 - 媒体消耗。`,
      sortable: true
    },
    {
      label: '利润率',
      width: 120,
      prop: 'profitRate',
      align: 'left',
      desc: `${title}统计的利润率，利润率=(广告收入-媒体消耗)/广告收入*100。`,
      sortable: true
    },
    {
      label: '利润（分成后）',
      width: 150,
      prop: 'shareProfit',
      align: 'left',
      desc: `${title}统计的分成后利润，利润（分成后）=收入（分成后）-  媒体消耗。`,
      sortable: true
    },
    {
      label: '百万次请求收益',
      width: 130,
      prop: 'advRequestPrice',
      align: 'left',
      sortable: true,
      desc: `${title}统计每百万次请求产生的收益，用于衡量广告效果，百万次请求收益=（广告收入/广告请求）*1000000。`
    }
    ]
  },
  {
    title: '价格数据',
    list: [{
      label: '竞价响应eCPM',
      width: 180,
      prop: 'askPrice',
      align: 'left',
      desc: `广告平台在竞价响应时返回的平均eCPM。`,
      sortable: true
    },
    {
      label: '媒体成交eCPM',
      width: 150,
      prop: 'estimatedRevenueEcpm',
      align: 'left',
      desc: `供应商展示广告的eCPM，媒体成交eCPM=（媒体消耗/${title}统计的展示）*1000。`,
      sortable: true
    },
    {
      label: '流量填充eCPM',
      width: 150,
      prop: 'priceEcpm',
      align: 'left',
      desc: `供应商收到的填充时的eCPM。`,
      sortable: true
    },
    {
      label: '广告收入eCPM',
      width: 130,
      prop: 'advEcpm',
      align: 'left',
      sortable: true,
      desc: `广告展示时与广告平台成交的eCPM，广告收入eCPM=（广告收入/${title}统计的展示）*1000。`
    }, {
      label: '媒体消耗CPC',
      width: 100,
      prop: 'cpc',
      align: 'left',
      desc: `供应商每次点击广告的价格，媒体消耗CPC=媒体消耗/点击。`,
      sortable: false
    },
    {
      label: '广告成交CPC',
      width: 130,
      prop: 'advCpc',
      align: 'left',
      sortable: true,
      desc: `广告平台每次点击的价格，广告成交CPC=广告收入/点击。`
    },
    {
      label: '竞价总和',
      width: 130,
      prop: 'askPriceSum',
      align: 'left',
      sortable: true,
      desc: `广告平台在竞价响应时返回的出价总和`
    },
    {
      label: '媒体底价',
      width: 130,
      prop: 'bidfloor',
      align: 'left',
      sortable: true,
      desc: `供应商在请求${title}时传入的底价。该底价在利润策略底价配置为媒体优先、媒体百分比时生效。`
    }
    ]
  },
  {
    title: 'CPA数据',
    isCpa: true,
    list: [{
      label: '唤端',
      width: 130,
      prop: 'huanduan',
      align: 'left',
      sortable: true,
      desc: `广告主回调的唤醒成功数`
    },
    {
      label: '巨浪唤端',
      width: 130,
      prop: 'julangHuanduan',
      align: 'left',
      sortable: true,
      desc: `广告主回调的巨浪唤醒成功数`
    },
    {
      label: '巨浪⾸唤',
      width: 130,
      prop: 'julangShouhuan',
      align: 'left',
      sortable: true,
      desc: `广告主回调的巨浪首次唤醒成功数`
    },
    {
      label: '巨浪全⽹⾸唤',
      width: 160,
      prop: 'quanwangShouhuan',
      align: 'left',
      sortable: true,
      desc: `广告主回调的巨浪全网首次唤醒成功数`
    },
    {
      label: '唤起率',
      width: 130,
      prop: 'huanqiRate',
      align: 'left',
      sortable: true,
      desc: `巨浪换端/点击*100`
    },
    {
      label: '首唤率',
      width: 130,
      prop: 'shouhuanRate',
      align: 'left',
      sortable: true,
      desc: `巨浪全网首唤/点击*100`
    },
    {
      label: '唤端认可率',
      width: 160,
      prop: 'huanduanConfirmRate',
      align: 'left',
      sortable: true,
      desc: `巨浪全网首唤/巨浪首唤*100`
    },
    {
      label: 'CPA收入',
      width: 130,
      prop: 'transformPrice',
      align: 'left',
      sortable: true,
      desc: `巨浪全网首唤*单价（平台配置）`
    },
    {
      label: 'CPA利润',
      width: 130,
      prop: 'cpaProfit',
      align: 'left',
      sortable: true,
      desc: `CPA收入 - 媒体消耗`
    },
    {
      label: '首唤成本',
      width: 130,
      prop: 'advPriceQuanwangShouhuan',
      align: 'left',
      sortable: true,
      desc: `广告收入/巨浪全⽹⾸唤`
    }
    ]
  },
  {
    title: '其他数据',
    list: [{
      label: '竞价响应率',
      width: 150,
      prop: 'askPriceFilledRate',
      align: 'left',
      desc: `广告平台响应广告请求的占比。竞价响应率 = 竞价响应次数 / 竞价次数 * 100。`,
      sortable: true
    },
    {
      label: '竞价成功率',
      width: 140,
      prop: 'bidSuccessRate',
      align: 'left',
      desc: `广告填充后，参与竞价并获胜的占比。竞价成功率=竞胜数/填充数。`,
      sortable: false
    },
    {
      label: '竞价失败率',
      width: 140,
      prop: 'bidFailRate',
      align: 'left',
      desc: `广告填充后，参与竞价但失败的占比。竞价失败率=竞败数/填充数。`,
      sortable: false
    },
    {
      label: '展示占比',
      width: 140,
      prop: 'display',
      align: 'left',
      desc: `${title}统计不同维度下的广告展示占比，用于衡量广告效果。`,
      sortable: false
    },
    {
      label: '媒体消耗占比',
      width: 120,
      prop: 'revenuePercentage',
      align: 'left',
      desc: `${title}统计不同维度下的媒体消耗占比，用于衡量流量效果；`,
      sortable: false
    },

    {
      label: '唤醒成功率',
      width: 100,
      prop: 'awakenSuccessRate',
      align: 'left',
      desc: `${title}统计唤醒广告的唤醒成功数占点击的比例，唤醒成功率=唤醒成功数/点击数 * 100 `,
      sortable: false
    },
    {
      label: '竞胜数',
      width: 100,
      prop: 'winSuccess',
      align: 'left',
      sortable: true,
      desc: `${title}统计的广告竞胜回传次数，由于统计口径差异、网络丢包等因素，${title}统计的竞胜数据与广告平台竞胜数据可能存在差异。`
    },
    {
      label: '竞败数',
      width: 100,
      prop: 'winFail',
      align: 'left',
      sortable: true,
      desc: `${title}统计的广告竞败回传次数。`
    }
    ]
  }
  ],
  sureArr: [{
    label: '流量请求',
    width: 140,
    prop: 'request',
    align: 'left',
    desc: `供应商向${title}发起请求的次数。`,
    sortable: true
  },
  {
    label: '展示',
    width: 150,
    prop: 'impress',
    align: 'left',
    desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异。`,
    sortable: true
  },
  {
    label: '点击',
    width: 120,
    prop: 'click',
    align: 'left',
    desc: `${title}统计的广告点击数，由于统计口径差异、网络丢包等因素，${title}统计的点击数据与广告平台点击数据可能存在差异`,
    sortable: true
  },
  {
    label: '点击率',
    width: 120,
    prop: 'clickRate',
    align: 'left',
    desc: `${title}统计的点击率，点击率=点击数/展示数`,
    sortable: false
  },
  {
    label: '媒体成交eCPM',
    width: 150,
    prop: 'estimatedRevenueEcpm',
    align: 'left',
    desc: `供应商展示广告的eCPM，媒体成交eCPM=（媒体消耗/${title}统计的展示）*1000`,
    sortable: true
  },
  {
    label: '媒体消耗CPC',
    width: 100,
    prop: 'cpc',
    align: 'left',
    desc: `供应商每次点击广告的价格，媒体消耗CPC=媒体消耗/点击`,
    sortable: false
  },
  {
    label: '媒体消耗',
    width: 150,
    prop: 'income',
    align: 'left',
    desc: `${title}统计与供应商成交的广告总价格，即流量成本，媒体消耗= 展示成功后供应商上报的展示价格*展示次数/1000`,
    sortable: true
  },
  {
    label: '广告收入',
    width: 150,
    prop: 'advPrice',
    align: 'left',
    desc: `${title}统计的展示后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
    sortable: true
  }
  ]
}
// CPA天级
export const cpaDayColumns = JSON.parse(JSON.stringify(localColumns))

// adx报表
export default {
  ...localColumns,
  targetArr: localColumns.targetArr.filter((item) => !item.isCpa)
}

const localHourColumnsTargetArr = localColumns.targetArr.map((targetItem) => {
  return {
    ...targetItem,
    list: targetItem.list.map((item) => {
      return {
        ...item,
        prop: item.prop,
        scopedSlots: {
          customRender: item.prop,
          title: `${item.prop}Title`
        },
        label: item.label,
        desc: item.desc,
        align: item.align,
        sortable: item.sortable
      }
    })
  }
})

const localHourColumnsSureArr = localColumns.sureArr.map((item) => {
  return {
    prop: item.prop,
    scopedSlots: {
      customRender: item.prop,
      title: `${item.prop}Title`
    },
    label: item.label,
    desc: item.desc,
    align: item.align,
    sortable: item.sortable
  }
})

// 小时报表
export const hourColumns = {
  targetArr: localHourColumnsTargetArr.filter((item) => !item.isCpa),
  sureArr: localHourColumnsSureArr
}

// cpa小时报表
export const cpaHourColumns = {
  targetArr: localHourColumnsTargetArr.filter((item) => item.isCpa),
  sureArr: localHourColumnsSureArr
}
