<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-04 16:57:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-26 12:22:54
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/RealTimeData/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-spin :spinning="spinning" wrapperClassName="spin-wrapper">
    <div class="real-time-data-container">
      <HomeTitile class="title" title="收入统计" />
      <div class="top">
        <div class="label">{{ sumText }}<a-popover overlayClassName="home-board-popover" placement="right">
          <template slot="content">
            <ul>
              <li @click="changeType(1)" :class="{ active: dimension === 1 }">总收入</li>
              <li @click="changeType(0)" :class="{ active: dimension === 0 }">今年累计收入</li>
            </ul>
          </template>
          <!-- <a-icon class="select-icon" type="select" /> -->
          <SwitchSvg style="margin-left:6px"/>
        </a-popover></div>
        <div class="value">
          <span class="money-text">{{ moneyText }}</span>
          <count-to
            :start-val="0"
            :decimals="2"
            :end-val="sumValue || 0"
            separator=","
          />
        </div>
      </div>
      <div class="bottom">
        <div class="item">
          <div class="label">昨日收入</div>
          <div class="value">
            <span class="money-text">{{ moneyText }}</span>
            <count-to :start-val="0" :decimals="2" :end-val="+fetchData.yesterdayRevenue || 0" />
          </div>
          <div class="ratio">环比:{{ fetchData.yesterdayRatio || 0 }}%</div>
        </div>
        <div class="item">
          <div class="label">七日收入</div>
          <div class="value">
            <span class="money-text">{{ moneyText }}</span>
            <count-to :start-val="0" :decimals="2" :end-val="+fetchData.sevenDaysRevenue || 0" />
          </div>
          <div class="ratio">环比:{{ fetchData.sevenDaysRatio || 0 }}%</div>
        </div>
        <div class="item">
          <div class="label">本月收入</div>
          <div class="value">
            <span class="money-text">{{ moneyText }}</span>
            <count-to :start-val="0" :decimals="2" :end-val="+fetchData.monthRevenue || 0" />
          </div>
          <div class="ratio">环比:{{ fetchData.monthRatio || 0 }}%</div>
        </div>
        <div class="item">
          <div class="label">上月收入</div>
          <div class="value">
            <span class="money-text">{{ moneyText }}</span>
            <count-to :start-val="0" :decimals="2" :end-val="+fetchData.lastMonthRevenue || 0" />
          </div>
          <div class="ratio">环比:{{ fetchData.lastMonthRatio || 0 }}%</div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { summaryData, changeSumDimension } from '@/apiForManage/dashboard'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import CountTo from 'vue-count-to'
import HomeTitile from '../../components/homeTitle.vue'
import SwitchSvg from './imgs/switch.svg?inline'
import { mapState } from 'vuex'
export default {
  name: 'RealTimeData',
  mixins: [mixRateAndMoneyMethod],
  components: {
    CountTo,
    HomeTitile,
    SwitchSvg
  },
  data () {
    return {
      fetchData: {},
      spinning: false
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      dashboardLayout: (state) => state.user.dashboardLayoutForMergex,
      chartInfo: (state) => state.user.dashboardLayoutForMergex.chartInfo,
      chartInfoRight: (state) => state.user.dashboardLayoutForMergex.chartInfoRight,
      adminUserId: (state) => state.user.adminUserId,
      chartInfoList: (state) => state.user.dashboardLayoutForMergex.chartInfoList,
      dimension: (state) => state.user.dashboardLayoutForMergex.dimension
    }),
    sumText () {
      return this.dimension === 1 ? '总收入' : '今年累计收入'
    },
    sumValue () {
      return this.dimension === 1 ? +this.fetchData.sumRevenue : +this.fetchData.yearRevenue
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      this.spinning = true
      const { data = {} } = await summaryData({})
      this.fetchData = data
      this.spinning = false
    },
    changeType (dimension) {
      changeSumDimension({
        dimension
      }).then(() => {
        this.$store.dispatch('GetInfo')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@labelColor: #5a607f;
@valueColor: #344563;
.real-time-data-container {
  user-select: none;
  padding: @smallMargin 0;
  height: 100%;
  background-image: url('./imgs/bac.png');
  border-radius: @mediumRadius;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  .title{
    padding-left: calc(16em / 14);
  }
  .top {
    height: calc(150em / 14);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: calc(10em / 14);
    .label {
      font-size: calc(20em / 14);
      color: @labelColor;
      line-height: calc(18em / 14);
      font-weight: 500;
      display: flex;
      align-items: center;
      .select-icon{
        // width: calc(10em / 12);
        // height: calc(10em / 12);
        font-size: calc(12em / 14);
      }
    }
    .value {
      font-size: calc(36em / 14);
      color: @valueColor;
      font-weight: 600;
      line-height: calc(25em / 14);
      display: flex;
      justify-content: center;
      .money-text{
        font-size: calc(10em / 14);
        transform: translateY(calc(2em / 14));
      }
    }
  }
  .bottom {
    padding: 0 calc(10em / 14);
    flex: 1 auto;
    display: flex;
    padding-bottom: calc(10em / 14);
    .item {
      max-width: calc(100% / 4);
      min-width: calc(100% / 4);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .label{
        font-size: calc(14em / 14);
        line-height: calc(20em / 14);
        margin-bottom: calc(7em / 14);
      }
      .value {
        width: 100%;
        font-size: calc(16em / 14);
        font-weight: 600;
        line-height: calc(22em / 14);
        color: @valueColor;
        display: flex;
        justify-content: center;
        margin-bottom: calc(4em / 14);
        .money-text{
          font-size: calc(10em / 14);
          transform: translateY(calc(2em / 14));
        }
      }
      .ratio {
        color: @labelColor;
        font-size: calc(13em / 14);
        line-height: calc(22em / 14);
      }
    }
  }
}
.spin-wrapper {
  height: 100%;
  ::v-deep {
    .ant-spin-container {
      height: 100%;
    }
  }
}
.home-board-popover .ant-popover-inner-content {
  // padding: calc(1em / 14) calc(6em / 14)!important;
  padding: 0;
  position: relative;
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      height: 2.14em;
      line-height: 2.14em;
      display: inline-block;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &.active {
        color: @primary-color;
      }
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>
