/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2023-12-19 17:25:28
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-30 16:20:30
 * @FilePath: /mediatom-web/src/utils/localDataNameForManage.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { clearStorage } from './clearStorage'

const localNameMap = {
  // 综合报表
  synthReport: 'manageSynth_1230',
  // 竞价广告配置
  RtbConfigReport: 'manageRTB_1230',
  configDetail: 'configDetail_1230',
  hourReport: 'hourReport_1230',
  cpaDaysReport: 'cpaDaysReport_1230',
  cpaHoursReport: 'cpaHoursReport_1230'
}

// 清除多余缓存
clearStorage('manageSynth_', localNameMap.synthReport)
clearStorage('manageRTB_', localNameMap.RtbConfigReport)
clearStorage('configDetail_', localNameMap.configDetail)
clearStorage('hourRepo_', localNameMap.hourReport)
clearStorage('cpaDaysReport_', localNameMap.cpaDaysReport)
clearStorage('cpaHoursReport_', localNameMap.cpaHoursReport)

export default localNameMap
