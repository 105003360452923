<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-30 16:25:07
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-05-31 15:11:26
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/SynthReport/SynthTemplate/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="template-container">
    <a-popover
      overlayClassName="cust-select-wrapper"
      ref="popover"
      trigger="click"
      placement="bottom"
      @visibleChange="visibleChange"
    >
      <div class="show_template_box">
        <div class="name" :class="{ has_name: currentTemplate.name }">{{ currentTemplate.name || '请选择模版' }}</div>
        <ArrowSvg class="icon" :class="{ up_icon: !visb, down_icon: visb }" />
      </div>
      <template slot="content">
        <div class="popover_wrapper">
          <ul>
            <li
              @click="handleClickTemplateItem(item)"
              class="templete_item"
              v-for="item in templateList"
              :key="item.id"
              :class="{ active: currentId === item.id }"
            >
              <span class="name">{{ item.name }}</span>
              <span
                class="control"
                v-if="currentId !== item.id"
                @click.stop="handleDeleteTemplateItem(item)"
              ><a-icon
                style="color: red"
                type="delete"
              /></span>
            </li>
          </ul>
          <div class="add_templete" @click="handleAddTemplete"><a-icon type="plus" />添加模板</div>
        </div>
      </template>
    </a-popover>
    <a-modal
      title="新建模板"
      :visible="visible"
      @ok="handleSubmit"
      @cancel="visible = false"
      dialogClass="search_template_modal"
      width="544px"
    >
      <a-form-model
        class="search_template_wrapper"
        ref="ruleForm"
        v-if="visible"
        :model="templatequery"
        v-bind="{
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        }"
        style="padding: 0 36px"
      >
        <a-form-model-item
          label="模板名称"
          prop="name"
          :rules="[{ required: true, message: '请输入模板名称', trigger: 'blur' }]"
        >
          <a-input v-model.trim="templatequery.name" style="width: 100%" placeholder="请输入模板名称" />
        </a-form-model-item>
        <a-form-model-item
          :rules="[{ required: true, message: '请输入数据时间范围', trigger: 'change' }]"
          label="数据时间范围"
          prop="timeRange"
        >
          <a-select allowClear style="width: 100%" v-model="templatequery.timeRange" placeholder="请选择数据时间范围">
            <a-select-option v-for="(item, index) in timeRangeList" :key="index" :value="item.value">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getReportTemplateList, insertReportTemplate, updateReportTemplateSts } from '@/apiForManage/report/synthReport'
import ArrowSvg from '@/assets/icons/arrow.svg?inline'
import timeRangeList from './timeRangeList'
import custTagObj from '../../reportColumns'
import localDataName from '@/utils/localDataNameForManage'
export default {
  name: 'SynthTemplate',
  components: { ArrowSvg },
  data () {
    return {
      templateList: [],
      currentId: undefined,
      visb: false,
      visible: false,
      templatequery: {
        name: undefined,
        timeRange: undefined
      },
      storageName: localDataName.synthReport, // 综合报表本地缓存key
      timeRangeList,
      templateInfo: {
        query: {},
        targetList: [],
        timeRange: []
      }
    }
  },
  computed: {
    currentTemplate () {
      return this.templateList.find((item) => item.id === this.currentId) || { name: undefined }
    }
  },
  created () {
    this.getTemplateList()
  },
  methods: {
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 获取搜索参数
          this.$emit('getTemplateInfo', this.getTemplateInfo)
          // 获取自定义指标
          if (localStorage.getItem(this.storageName)) {
            const { sureArr } = JSON.parse(localStorage.getItem(this.storageName))
            this.templateInfo.targetList = sureArr
          } else {
            const { sureArr } = custTagObj
            this.templateInfo.targetList = sureArr
          }
          // 保存时间范围
          this.templateInfo.timeRange = this.timeRangeList.find((item) => item.value === this.templatequery.timeRange).range
          this.addTemplate()
        } else {
          return false
        }
      })
    },
    async addTemplate () {
      const query = {
        name: this.templatequery.name,
        requestParams: JSON.stringify(this.templateInfo)
      }
      const resp = await insertReportTemplate(query)
      if (resp.code === 200) {
        this.$message.success('添加成功！')
        this.visible = false
        this.getTemplateList()
      }
    },
    getTemplateInfo (query) {
      this.templateInfo.query = query
    },
    handleAddTemplete () {
      this.visible = true
      this.templatequery.name = undefined
      this.templatequery.timeRange = undefined
      this.$refs.popover.$children[0].sVisible = false
    },
// 删除模版
    async handleDeleteTemplateItem (item) {
      updateReportTemplateSts({ id: item.id, sts: 'D' }).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功！')
          this.getTemplateList()
        }
      })
    },
    handleClickTemplateItem (item) {
      this.currentId = item.id
      this.$refs.popover.$children[0].sVisible = false
      const { requestParams = '' } = item
      const query = JSON.parse(requestParams)
      const { targetList = [] } = query
      localStorage.setItem(this.storageName, JSON.stringify({ sureArr: targetList, targetArr: custTagObj.targetArr }))
      this.$emit('changeQuery', query)
    },
    async getTemplateList () {
      const { data = [] } = await getReportTemplateList()
      this.templateList = data
    },
    visibleChange (e) {
      this.visb = e
    }
  }
}
</script>

<style lang="less" scoped>
.template-container {
  padding-bottom: @smallMargin;
  margin-bottom: @mediumMargin;
  border-bottom: 1px solid @compBorderColor;
}
.show_template_box {
  width: 207px;
  user-select: none;
  height: @compDefaultHeight;
  line-height: @compDefaultHeight;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid @compBorderColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 @compPadding;
  .name {
    color: @compDisabledColor;
    &.has_name {
      color: @compValueColor;
    }
  }
  .icon {
    transition: all 0.2s;
    &.down_icon {
      transform: rotate(180deg);
    }
  }
}
.popover_wrapper {
  width: 207px;
  border: 1px solid #fff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  ul{
    padding: 0;
    margin: 0;
    li{
      user-select: none;
      height: 34px;
      line-height: 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      font-size: 13px;
      cursor: pointer;
      &.active{
        background-color: fade(@primary-color, 10%);
        color: @primary-color;
      }
    }
  }
  .add_templete {
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border-top: 1px solid #e8e8e8;
    user-select: none;
  }
}
</style>
