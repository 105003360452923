<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-04 14:40:21
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-27 18:32:00
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/AdxReportChart/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adx-report-container">
    <div class="head-control">
      <div class="title-box">
        <HomeTitile class="title" title="流量趋势" />
      </div>
      <div class="search">
        <div class="range">
          <a-range-picker
            format="MM.DD"
            :placeholder="['Start', 'End']"
            @change="ChangeDate"
            :value="rangePickerDate"
            :allowClear="false"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end],
              上一个月: getLastMonthDuration()
            }"
            :disabledDate="disabledDate"
          >
            <a-icon slot="suffixIcon" type="calendar" /></a-range-picker>
        </div>
        <div class="search-item" v-if="role === 'SUPER_ADMIN'">
          <m-select-more
            style="width: 100%"
            v-model="searchquery.userIdList"
            :allData="adminUserList"
            :searchById="true"
            :hasIcon="false"
            :showId="false"
            label="账户"
            :showSelectedPart="true"
            :hasSearch="true"
            width="450px"
            :canSelectAll="true"
            @change="handleChangeUser"
            :hasPlease="false"
            :showLabel="false"
          />
        </div>
        <div class="search-item">
          <m-select-more
            style="width: 100%"
            v-model="searchquery.publisherIdList"
            :allData="supplierList"
            :searchById="true"
            :hasIcon="true"
            :showId="true"
            width="450px"
            label="供应商"
            :hasPlease="false"
            :showLabel="false"
            @change="changeSupplier"
          />
        </div>
        <div class="search-item">
          <m-select-more
            style="width: 100%"
            v-model="searchquery.placeIdList"
            :allData="placeList"
            :searchById="true"
            :hasIcon="true"
            :showId="true"
            width="450px"
            label="广告位"
            @change="handleSearch"
            :hasPlease="false"
            :showLabel="false"
          />
        </div>
        <div class="search-item">
          <m-select-more
            style="width: 100%"
            v-model="searchquery.platIdList"
            :allData="platList"
            :searchById="false"
            :hasIcon="false"
            :showId="false"
            label="广告平台"
            :showSelectedPart="true"
            width="450px"
            :canSelectAll="true"
            @change="changePlat"
            :hasPlease="false"
            :showLabel="false"
          />
        </div>
        <div class="search-item">
          <m-select-more
            style="width: 100%"
            v-model="searchquery.dspPlaceIdList"
            :allData="dspList"
            :searchById="true"
            :hasIcon="false"
            :showId="true"
            label="广告源"
            :showSelectedPart="true"
            width="450px"
            :canSelectAll="true"
            @change="handleSearch"
            :hasPlease="false"
            :showLabel="false"
          />
        </div>
        <div class="search-item">
          <a-select style="width: 100%" v-model="currentTarget1" @change="changeEcharts">
            <a-select-option v-for="(child, index) in targetList" :key="index" :value="child.id" :disabled="currentTarget2 === child.id">
              {{ child.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="search-item">
          <a-select class="target" v-model="currentTarget2" @change="changeEcharts">
            <a-select-option v-for="(child, index) in targetList" :key="index" :value="child.id" :disabled="currentTarget1 === child.id">
              {{ child.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="chart-wrapper">
      <a-spin :spinning="isLoading" wrapperClassName="spin-wrapper">
        <Chart :option="chartOption" />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Chart from '@/components/common/Chart'
import HomeTitile from '../../components/homeTitle.vue'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { getDuration } from '@/utils/duration'
import autoFontSize from '@/mixins/autoFontSize'
import { getDspList } from '@/apiForManage/common'
import { getFullTrend } from '@/apiForManage/report/synthReport'
import targetList from './targets'
import option from './options'
import { numFormat } from '@/utils/dealNumber'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  components: { HomeTitile, Chart },
  mixins: [mixDate, mixGetList, autoFontSize, rateAndMoneyMethod],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        userIdList: [],
        publisherIdList: [],
        placeIdList: [],
        platIdList: [],
        dspPlaceIdList: [],
        osIdList: [],
        positionIdList: [],
        platAccountIdList: [],
        countryCodeList: [],
        bundleList: []
      },
      dspList: [],
      currentTarget1: undefined,
      currentTarget2: undefined,
      targetList,
      isLoading: true,
      dataList: [],
      chartOption: {},
      colorList: [
        {
          c1: 'rgba(183, 105, 247, 1)',
          c2: 'rgba(138, 89, 251, 1)'
        },
        {
          c1: 'rgba(95, 233, 196, 1)',
          c2: 'rgba(62, 219, 223, 1)'
        },
        {
          c1: 'rgba(249, 169, 110, 1)',
          c2: 'rgba(248, 123, 104, 1)'
        },
        {
          c1: 'rgba(250, 84, 124, 1)',
          c2: 'rgba(247, 65, 108, 1)'
        },
        {
          c1: 'rgba(22, 178, 246, 1)',
          c2: 'rgba(31, 125, 238, 1)'
        },
        {
          c1: 'rgba(248, 202, 116, 1)',
          c2: 'rgba(252, 159, 74, 1)'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    }),
    propName1 () {
      return this.targetList.find((item) => item.id === this.currentTarget1)?.name
    },
    propName2 () {
      return this.targetList.find((item) => item.id === this.currentTarget2)?.name
    }
  },
  async created () {
    this.currentTarget1 = this.targetList[0].id
    this.currentTarget2 = this.targetList[1].id
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.role === 'SUPER_ADMIN' && (await this.getAdminUserList())
    await this.getSupplierList()
    await this.getPlaceList()
    await this.getPlatListByuser()
    await this.getDspList()
    await this.getFullCharts()
  },
  methods: {
    getFullCharts () {
      this.isLoading = true
      getFullTrend(this.searchquery)
        .then((resp) => {
          const { data = [] } = resp
          this.dataList = data
          this.isLoading = false
          this.changeEcharts()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    changeEcharts () {
      const opt = JSON.parse(JSON.stringify(option))
      opt.legend.data = []
      opt.xAxis.data = this.dataList.map((item) => item.date)
      const lineData1 = this.dataList.map((item, index) => item[this.currentTarget1])
      const lineData2 = this.dataList.map((item, index) => item[this.currentTarget2])
      opt.series = [
        {
        name: this.propName1,
        type: 'line',
        smooth: true,
        data: lineData1,
        symbol: 'circle', // 设定为实心点
        symbolSize: 4, // 设定实心点的大小
        itemStyle: {
          normal: {
            color: this.colorList[0].c1,
            lineStyle: {
              color: this.colorList[0].c1,
              width: 1.8
            }
          }
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: this.colorList[0].c2 // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
              }
            ],
            globalCoord: false // 缺省为 false
          }
        }
      },
      {
        name: this.propName2,
        type: 'line',
        smooth: true,
        data: lineData2,
        symbol: 'circle', // 设定为实心点
        symbolSize: 4, // 设定实心点的大小
        itemStyle: {
          normal: {
            color: this.colorList[2].c1,
            lineStyle: {
              color: this.colorList[2].c1,
              width: 1.8
            }
          }
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: this.colorList[2].c2 // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
              }
            ],
            globalCoord: false // 缺省为 false
          }
        }
      }
      ]
      // formatter方法
      opt.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const seriesProp = targetList.find((i) => i.name === item.seriesName).id
          const temp = {}
          if (this.isMoney(seriesProp)) {
            temp.value = this.moneyText + numFormat(item.value, 3, '')
          } else if (this.isRota(seriesProp)) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
        })

        return params[0].axisValue + '<br>' + str
      }
      this.chartOption = opt
    },
    async getDspList (platIds = []) {
      const { data = [] } = await getDspList({
        platAccountIdList: [],
        platIdList: platIds
      })
      data.forEach((item) => {
        item.searchId = item.platPlaceId
        item.id = '' + item.id
      })
      this.dspList = data
    },
    async changePlat (ids) {
      await this.getDspList(ids)
      this.handleSearch()
    },
    // 应用改变查广告位
    async changeSupplier (ids) {
      await this.getPlaceList(ids)
      this.handleSearch()
    },
    async handleChangeUser () {
      await this.getSupplierList(this.searchquery.userIdList)
      this.changeSupplier()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: date[1].format('YYYY-MM-DD'),
        dateStart: date[0].format('YYYY-MM-DD')
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    handleSearch () {
      this.getFullCharts()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../range.less');
.adx-report-container {
  height: 100%;
  padding: 10px @smallMargin @smallMargin @smallMargin;
  background: #fff;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  border-radius: @mediumRadius;
  display: flex;
  flex-direction: column;
  .head-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search{
      display: flex;
      align-items: center;
    }
      .title-box {
        margin-right: calc(3em / 14);
        height: calc(20em / 14);
      }
      .range{
        transform: translateY(3px);
        margin-right: calc(6em / 14);
      }
      .search-item {
        width: calc(107em / 14);
        margin-right: calc(6em / 14);
      }
  }
  .chart-wrapper {
    flex: 1 auto;
  }
}
.spin-wrapper {
  height: 100%;
  ::v-deep {
    .ant-spin-container {
      height: 100%;
    }
  }
}
</style>
