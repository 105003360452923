<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 15:16:27
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-11 18:08:23
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/Mapping/MappingTable/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="mapping-table">
    <a-spin :spinning="isLoading">
      <el-table :data="dataList" :row-key="(row) => row.id">
        <!-- 状态 -->
        <el-table-column prop="sts" label="状态">
          <template slot-scope="{ row }">
            <a-switch size="small" :checked="row.sts === 'A'" @click="handleChangeSts(row)"></a-switch>
          </template>
        </el-table-column>
        <!-- 映射名称 -->
        <el-table-column prop="name" label="映射名称" min-width="200">
          <div class="name-box" slot-scope="{ row }">
            {{ row.name }}
          </div>
        </el-table-column>
        <!-- 供应商名称 -->
        <el-table-column prop="publisherName" label="供应商名称" min-width="145">
          <div class="name-box" slot-scope="{ row }">
            {{ row.publisherName }}
          </div>
        </el-table-column>
        <!-- 供应商ID -->
        <el-table-column prop="publisherId" label="供应商ID" min-width="190">
          <div slot-scope="{ row }" class="copy-row">
            <span class="id">{{ row.publisherId }}</span
            ><CopySvg class="copy-icon" @click="copyId(row.publisherId)" />
          </div>
        </el-table-column>
        <!-- 广告位名称 -->
        <el-table-column prop="placeName" label="广告位名称" min-width="175">
          <div class="name-box" slot-scope="{ row }">
            {{ row.placeName }}
          </div>
        </el-table-column>
        <!-- 广告位ID -->
        <el-table-column prop="placeId" label="广告位ID" min-width="219">
          <div slot-scope="{ row }" class="copy-row">
            <span class="id">{{ row.placeId }}</span
            ><CopySvg class="copy-icon" @click="copyId(row.placeId)" />
          </div>
        </el-table-column>
        <!-- 广告样式 -->
        <el-table-column prop="positionName" label="广告样式" min-width="114"></el-table-column>
        <el-table-column label="映射数量" min-width="84">
          <template slot-scope="{ row }"> {{ (row.trafficPlaceIdList || []).length }}个 </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" min-width="123" class-name="action-column">
          <template slot-scope="{ row }">
            <a-popover placement="bottom" :ref="`popover${row.id}`" overlayClassName="mapping_setting_popover">
              <GroupSvg style="cursor: pointer;color:#fff;" />
              <div class="popover-btn-box" slot="content">
                <div class="popover-btn" @click="handleUpdateMapping(row)"><EditSvg class="icon"/>编辑</div>
                <div class="popover-btn" @click="handleDeleteMapping(row)"><DeleteSvg class="icon"/>删除</div>
              </div>
            </a-popover>
            <!-- <span class="btn primary-text" @click="handleUpdateMapping(row)">编辑</span>
            <span class="btn primary-text" @click="handleDeleteMapping(row)">删除</span> -->
          </template>
        </el-table-column>
      </el-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
      <m-empty v-else style="height: 341px" imgHeight="176px">
        <template #description>暂无数据 </template>
      </m-empty>
    </a-spin>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="映射"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
    <EditMapping
      :visible="addMappingVisible"
      :mappingInfo="mappingInfo"
      :isAdd="isAdd"
      @modalCancel="addMappingVisible = false"
      @editSuccess="getDataList"
    />
  </div>
</template>

<script>
import CopySvg from '@/assets/icons/copy.svg?inline'
import { copy } from '@/utils/string'
import { getMappingPage, deleteMapping, updateMapping } from '@/apiForManage/functionList/mapping'
import EditMapping from '../components/EditMapping'
import actionIconMixin from '@/mixins/actionIcon'
export default {
  name: 'MappingTable',
  components: { EditMapping, CopySvg },
  mixins: [actionIconMixin],
  data () {
    return {
      // 加载中
      isLoading: false,
      // 分页数据
      dataList: [],
      // 搜索项
      searchquery: {
        page: 1,
        limit: 10
      },
      // 总数
      total: 0,
      // 添加编辑弹窗
      addMappingVisible: false,
      // 当前映射信息
      mappingInfo: {},
      // 是否添加
      isAdd: false,
      // 删除弹窗
      delVisible: false
    }
  },
  methods: {
    async handleChangeSts (row) {
      const resp = await updateMapping({
        ...row,
        sts: row.sts === 'A' ? 'S' : 'A'
      })
      if (resp.code === 200) {
        this.$message.success('修改成功')
        this.getDataList()
      }
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    },
    // 单个删除
    handleDeleteMapping (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.mappingInfo = JSON.parse(JSON.stringify(row))
      this.delVisible = true
    },
    // 删除确认
    async handleDeleteItem () {
      const resp = await deleteMapping({ id: this.mappingInfo.id })
      if (resp.code === 200) {
        this.$message.success('删除成功')
        this.delVisible = false
        this.searchquery.page = 1
        this.getDataList()
      }
    },
    // 编辑
    handleUpdateMapping (row) {
      this.mappingInfo = JSON.parse(JSON.stringify(row))
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.addMappingVisible = true
      this.isAdd = false
    },
    // 添加（外部调用）
    handleAddMapping () {
      this.addMappingVisible = true
      this.isAdd = true
    },
    // 搜索（外部调用）
    handleSearch (query) {
      this.searchquery.page = 1
      this.searchquery = {
        ...this.searchquery,
        ...query
      }
      this.getDataList()
    },
    // 获取分页数据
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getMappingPage(this.searchquery)
      const { items = [], total = 0 } = data
      this.dataList = items
      this.total = total
      this.isLoading = false
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mapping-table {
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  border-radius: @mediumRadius;
  padding: 0 @smallMargin;
  margin-top: @mediumMargin;
  background-color: #fff;
  .copy-row {
    display: flex;
    align-items: center;
    .id {
      margin-right: 10px;
    }
    .copy-icon {
      cursor: pointer;
    }
  }
  .btn {
    margin: 0 5px;
    cursor: pointer;
  }
  .name-box {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pagination {
    margin: @smallMargin 0;
  }
}
.popover-btn-box{
  display: flex;
  flex-direction: column;
  .popover-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
    .icon{
      margin-right: 6px;
      color: white;
    }
  }
}
</style>

<style lang="less">
.mapping_setting_popover{
  .ant-popover-inner-content{
    padding: 6px 12px;
  }
}
</style>
