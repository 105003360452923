<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-05 10:05:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:31:29
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/EditWarning/ConditionForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="condition-form-wrapper">
    <!-- 预警范围 -->
    <a-form-model-item
      prop="triggerCondition"
      label="预警条件"
      :rules="[{ required: true, message: '请选择预警范围', trigger: 'change' }]"
    >
      <div class="rule-wrapper">
        <div class="rule-num">已添加({{ formquery.earlyWarningRuleList && formquery.earlyWarningRuleList.length }}/10)</div>
        <div class="rule-incloud">
          <a-radio-group v-model="formquery.triggerCondition">
            <a-radio :value="0"> 满足所有条件触发 </a-radio>
            <a-radio :value="1"> 满足任意条件触发 </a-radio>
          </a-radio-group>
          <a-button type="link" icon="plus" @click="addRuleItem">添加预警条件</a-button>
        </div>
      </div>
    </a-form-model-item>
    <!-- 预警条件 -->
    <a-form-model-item
      :wrapperCol="{ offset: 3, span: 15 }"
      v-if="formquery.earlyWarningRuleList && formquery.earlyWarningRuleList.length"
    >
      <div class="rule-wrapper list">
        <div class="rule-list">
          <div class="left">
            <span>{{ formquery.triggerCondition === 0 ? '且' : '或' }}</span>
          </div>
          <div class="right">
            <div class="rule-item" v-for="(item, index) in formquery.earlyWarningRuleList" :key="index">
              <span class="today">今天</span>
              <!-- 数据指标 -->
              <a-form-model-item
                class="ruleId form-item"
                :prop="`earlyWarningRuleList[${index}].ruleId`"
                :rules="[{ required: true, message: '请选择数据指标', trigger: 'change' }]"
              >
                <a-select v-model="item.ruleId" placeholder="请选择数据指标" style="width: 158px;">
                  <a-select-option v-for="rule in ruleIdListComp" :key="rule.id">{{ rule.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 类型 -->
              <a-form-model-item
                class="ruleType form-item"
                :prop="`earlyWarningRuleList[${index}].ruleType`"
              >
                <a-select v-model="item.ruleType" style="width: 77px;">
                  <a-select-option v-for="rule in ruleTypeList" :key="rule.id">{{ rule.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 时间 -->
              <a-form-model-item
                class="ruleDate form-item"
                :prop="`earlyWarningRuleList[${index}].dateType`"
              >
                <a-select v-model="item.dateType" style="width: 130px;">
                  <a-select-option v-for="rule in ruleDateList" :key="rule.id">{{ rule.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 数值 -->
              <a-form-model-item
                class="value form-item"
                :prop="`earlyWarningRuleList[${index}].value`"
                :rules="[{ required: true, message: '请输入触发值', trigger: 'blur' }]"
              >
                <a-input type="number" style="width: 174px" :min="0" v-model.number.trim="item.value" placeholder="请输入触发值"><span slot="addonAfter">%</span></a-input>
              </a-form-model-item>
              <a-icon type="close" @click="handleDeleteItem(index)" class="icon"></a-icon>
            </div>
          </div>
        </div>
      </div>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ruleIdList } from './ruleIdList'
export default {
  name: 'ConditionForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        earlyWarningRuleList: [],
        triggerCondition: 0
      },
      ismanager: process.env.VUE_APP_ISMANAGER === 'true',
      ruleIdList,
      ruleTypeList: [
        {
          name: '<=',
          id: 0
        },
        {
          name: '>=',
          id: 1
        }
      ],
      ruleDateList: [
        {
          name: '前一天',
          id: 0
        },
        // {
        //   name: '昨天',
        //   id: 1
        // },
        {
          name: '上周同一天',
          id: 2
        }
      ]
    }
  },
  computed: {
    ruleIdListComp () {
      return this.ruleIdList.filter((item) => item.target.includes(this.formquery.target))
    },
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...JSON.parse(JSON.stringify(val))
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        earlyWarningRuleList: [],
        triggerCondition: 0
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    handleDeleteItem (index) {
      this.formquery.earlyWarningRuleList.splice(index, 1)
      this.$message.success('删除成功')
    },
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    },
    addRuleItem () {
      if (this.formquery.earlyWarningRuleList.length >= 10) {
        this.$message.error('最多只能添加10个预警条件')
        return
      }
      this.formquery.earlyWarningRuleList.push({
        ruleId: undefined,
        ruleType: 0,
        dateType: 0,
        value: undefined
      })
    }
  }
}
</script>

<style lang="less" scoped>
.condition-form-wrapper {
  background: #fff;
  ::v-deep {
    .has-error .ant-select-selection {
      border-color: var(--compBorderColor);
    }
    .has-error .ant-select-arrow {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .rule-wrapper{
    display: flex;
    flex-direction: column;
    &.list{
      border-bottom: 1px solid rgba(21, 34, 50, 0.12);
      padding-bottom: 10px;
    }
    .rule-num{
      color: @compDisabledColor;
      padding-bottom: 10px;
    }
    .rule-incloud{
      display: flex;
      align-items: center;
    }
    .rule-list{
      display: flex;
      .left{
        width: 15px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        &::before, &::after{
          content: '';
          width: 1px;
          height: calc(50% - 1em / 2 - 6px);
          background-color: #e3e4e6;
          position: absolute;
          left: 50%;
        }
        &::before{
          top: 3px;
        }
        &::after{
          bottom: 3px;
        }
      }
      .right{
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        .rule-item{
          display: flex;
          flex-wrap: nowrap;
          .today{
            margin-right: 10px;
          }
          .form-item{
            margin-right: 16px;
          }
        }
        .icon{
          margin-top: 15px;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
