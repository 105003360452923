export default {
  methods: {
    /**
     * @description 跳转到第一个验证失败的元素,需要将表单元素id与验证字段名对应
     * @param {*} errList 验证失败信息
     * @returns
     */
    scrollToErrors (errList) {
      const keys = Object.keys(errList)
      if (keys.length === 0) {
        return
      }
      const firstKey = keys[0]
      const dom = document.getElementById(firstKey)
      if (!dom) {
        return
      }
      dom.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
