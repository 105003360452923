/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:59:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-11 15:56:11
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/HoursReport/HourTable/list.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import store from '@/store'
const title = store.getters.title
export const dateArr = [
  {
    title: '日期',
    prop: 'date',
    dataIndex: 'date',
    width: 130,
    align: 'left',
    label: '日期',
    scopedSlots: { customRender: 'date' },
    fixed: true,
    isDimension: true
  }
]

export const allDimesion = [
  {
    key: 'publisher_id',
    prop: 'publisherName',
    isWrap: false,
    label: '供应商',
    checked: false,
    width: 120
  },
  {
    key: 'place_id',
    prop: 'placeName',
    isWrap: false,
    label: '广告位',
    checked: false,
    width: 180
  },
  {
    key: 'country_code',
    prop: 'countryName',
    isWrap: false,
    label: '地区',
    checked: false,
    width: 150
  }
]
export const targets = [
  {
    title: '总费用',
    dataIndex: 'totalCost',
    name: '总费用',
    width: 120,
    align: 'left',
    scopedSlots: { customRender: 'totalCost', title: 'totalCostTitle' },
    ellipsis: false,
    desc: `总费用涵盖了平台使用的全部成本，主要包括两部分：一是使用平台开放流量的费用，二是平台基础请求填充的平台服务费。`
},
{
  title: `${title}开放流量费用`,
  dataIndex: 'income',
  name: `${title}开放流量费用`,
  width: 220,
  align: 'left',
  scopedSlots: { customRender: 'income', title: 'incomeTitle' },
  ellipsis: false,
  desc: `购买MergeX开放流量的流量费`
},
{
  title: 'QPS请求费用',
  dataIndex: 'advRequestCost',
  name: 'QPS请求费用',
  width: 220,
  align: 'left',
  scopedSlots: { customRender: 'advRequestCost', title: 'advRequestCostTitle' },
  ellipsis: false,
  desc: `平台根据广告请求和流量填充的次数收取使用费。具体标准为：每一百万次广告请求收费1元，每一百万次流量填充收费30元。`
},
{
  title: '广告请求数',
  dataIndex: 'advRequest',
  name: '广告请求数',
  width: 220,
  align: 'left',
  scopedSlots: { customRender: 'advRequest' },
  ellipsis: false
},
{
  title: 'QPS填充费用',
  dataIndex: 'responseCost',
  name: 'QPS填充费用',
  width: 120,
  align: 'left',
  scopedSlots: { customRender: 'responseCost' },
  ellipsis: false
},
{
  title: '流量填充次数',
  dataIndex: 'response',
  name: '流量填充次数',
  width: 120,
  align: 'left',
  scopedSlots: { customRender: 'response', title: 'responseTitle' },
  ellipsis: false,
  desc: `平台根据广告请求和流量填充的次数收取使用费。具体标准为：每一百万次广告请求收费1元，每一百万次流量填充收费30元。`
}
]
