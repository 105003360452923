var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-container"},[_c('a-form-model',_vm._b({ref:"accountForm",staticClass:"form",attrs:{"model":_vm.accountForm,"labelAlign":"left"}},'a-form-model',_vm.layout,false),[_c('a-form-model-item',{attrs:{"label":"注册邮箱"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.accountForm.loginId),callback:function ($$v) {_vm.$set(_vm.accountForm, "loginId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.loginId"}})],1),_c('a-form-model-item',{attrs:{"label":"角色"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.accountForm.rolesName),callback:function ($$v) {_vm.$set(_vm.accountForm, "rolesName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.rolesName"}})],1),_c('a-form-model-item',{attrs:{"label":"账号名称","prop":"name","rules":{
        required: true,
        message: '请输入账号名称',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入账号名称"},model:{value:(_vm.accountForm.name),callback:function ($$v) {_vm.$set(_vm.accountForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.name"}})],1),_c('a-form-model-item',{attrs:{"label":"公司名称","prop":"company","rules":{
        required: true,
        message: '请输入公司名称',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入公司名称"},model:{value:(_vm.accountForm.company),callback:function ($$v) {_vm.$set(_vm.accountForm, "company", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.company"}})],1),_c('a-form-model-item',{attrs:{"label":"联系人","prop":"contact","rules":{
        required: true,
        message: '请输入联系人',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入联系人"},model:{value:(_vm.accountForm.contact),callback:function ($$v) {_vm.$set(_vm.accountForm, "contact", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.contact"}})],1),_c('a-form-model-item',{attrs:{"label":"手机号码","prop":"mobile","rules":{
        required: true,
        message: '请输入手机号码',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入手机号码"},model:{value:(_vm.accountForm.mobile),callback:function ($$v) {_vm.$set(_vm.accountForm, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.mobile"}})],1),_c('a-form-model-item',{attrs:{"label":"报表时区","prop":"timeZone","rules":{
        required: true,
        message: '请选择报表时区',
        trigger: 'change'
      }}},[_c('a-select',{attrs:{"disabled":"","placeholder":"请选择报表时区"},model:{value:(_vm.accountForm.timeZone),callback:function ($$v) {_vm.$set(_vm.accountForm, "timeZone", $$v)},expression:"accountForm.timeZone"}},_vm._l((_vm.timeZoneList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"币种","prop":"cur"}},[_c('a-radio-group',{attrs:{"disabled":""},model:{value:(_vm.accountForm.cur),callback:function ($$v) {_vm.$set(_vm.accountForm, "cur", $$v)},expression:"accountForm.cur"}},[_c('a-radio',{attrs:{"value":0}},[_vm._v("人民币")]),_c('a-radio',{attrs:{"value":1}},[_vm._v("美元")])],1)],1)],1),_c('div',{staticClass:"btn"},[_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("修改")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }