<template>
  <div class="synth-table-container">
    <div class="control">
      <div class="left">
        <span class="dimension-btn" @click="Visible = true"><a-icon class="dimension-icon" type="radar-chart" />维度</span>
        <div class="dimension_list sort-checkbox-group">
          <a-checkbox @click="changeDimension(item)" style="margin-right: 2px" v-for="item in allDimesion" :key="item.prop" :checked="searchquery.groupByList.includes(item.key)">{{ item.name }}</a-checkbox>
        </div>
      </div>
      <div>
        <m-link-button @click="getTableData" class="tag-btn" iconPos="right">刷新<RefreshSvg slot="icon"/></m-link-button>
        <m-link-button @click="custTagsVisible = true" class="tag-btn" iconPos="right">自定义指标<TagsSvg slot="icon"/></m-link-button>
        <m-link-button :disabled="downLoading" @click="exportReport" iconPos="right">导出<ExportSvg slot="icon"/></m-link-button>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust synth_table"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        :scroll="{ x: true }"
        @change="handleSort"
        :loading="isLoading"
      >
        <!-- 应用 -->
        <div slot="appName" slot-scope="appName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            <a-popover placement="bottom" trigger="hover">
              <template #content>
                <div class="synth-popover">
                  <div class="synth-popover-item">
                    <span>应用：{{ appName }}</span>
                  </div>
                  <div class="synth-popover-item">
                    <span>应用ID：{{ record.appId }}</span>
                    <a-button size="small" type="link" @click="copyId(record.appId)">复制</a-button>
                  </div>
                </div>
              </template>
              <IosSvg v-if="record.os === 0" style="width: 14px; height: 14px" />
              <AndroidSvg v-if="record.os === 1" style="width: 14px; height: 14px" />
              {{ appName }}
            </a-popover>
          </div>
        </div>
        <!-- 广告位 -->
        <div slot="placeName" slot-scope="placeName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            <a-popover placement="bottom" trigger="hover">
              <template #content>
                <div class="synth-popover" style="width: 300px">
                  <div class="synth-popover-item">
                    <span
                    ><span>广告位名称：{{ record.placeName }}</span></span
                    >
                  </div>
                  <div class="synth-popover-item">
                    <span
                    ><span>广告位ID：{{ record.placeId }}</span></span
                    >
                    <a-button size="small" type="link" @click="copyId(record.placeId)">复制</a-button>
                  </div>
                </div>
              </template>
              <div style="max-width: 130px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                {{ placeName }}
              </div>
            </a-popover>
          </div>
        </div>
        <!-- 操作系统 -->
        <template slot="os" slot-scope="os">{{ os === 1 ? '安卓' : os === 0 ? 'IOS' : '' }}</template>
        <!-- 广告源 -->
        <div slot="dspPlaceName" slot-scope="dspPlaceName, record, index">
          <div class="fixed_item" v-if="index !== 0">
            <a-popover placement="bottom" trigger="hover">
              <template #content>
                <div class="synth-popover">
                  <div class="synth-popover-item">
                    <span>广告平台：{{ record.platName }}</span>
                  </div>
                  <div class="synth-popover-item">
                    <span>账号名称：{{ record.platAccountName }}</span>
                  </div>
                  <div class="synth-popover-item">
                    <span>广告源名称：{{ record.dspPlaceName }}</span>
                  </div>
                  <div class="synth-popover-item">
                    <span>广告源ID：{{ record.platPlaceId }}</span>
                    <a-button size="small" type="link" @click="copyId(record.platPlaceId)">复制</a-button>
                  </div>
                </div>
              </template>
              {{ dspPlaceName }}
            </a-popover>
          </div>
        </div>
        <!-- os -->
        <template slot="os" slot-scope="OS, record, index">
          <span v-if="index !== 0">{{ OS ? '安卓' : 'IOS' }}</span>
        </template>
        <!-- 广告样式 -->
        <div slot="positionName" slot-scope="positionName, record, index">
          <div class="fixed_item" :title="positionName" v-if="index !== 0">
            {{ positionName }}
          </div>
        </div>
        <!-- 广告平台 -->
        <div slot="platName" slot-scope="platName, record, index">
          <div class="fixed_item" :title="platName" v-if="index !== 0">
            {{ platName }}
          </div>
        </div>
        <!-- 应用包名 -->
        <div slot="bundle" slot-scope="bundle, record, index">
          <div class="fixed_item" :title="record.bundleName" v-if="index !== 0">
            <!-- <IosSvg v-if="record.os === 0" style="width: 14px; height: 14px" />
            <AndroidSvg v-if="record.os === 1" style="width: 14px; height: 14px" /> -->
            {{ bundle || record.bundleName }}
          </div>
          <div class="fixed_item">
            {{ record.bundleName }}
          </div>
        </div>
        <!-- 自定义指标 -->
        <template v-for="item in sureArr" :slot="item.prop" slot-scope="text">
          <div :key="item.prop" v-if="isRota(item.prop)" :title="text + '%'">{{ text }}%</div>
          <div :key="item.prop" v-else-if="isMoney(item.prop)" :title="moneyText + text">{{ moneyText }}{{ text }}</div>
          <div :key="item.prop" v-else :title="text">{{ text }}</div>
        </template>
      </a-table>
    </div>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 1"
      show-size-changer
      :current="searchquery.page"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
      :pageSize="searchquery.limit"
    />
    <m-empty v-else style="height: 341px" imgHeight="176px">
      <template #description>暂无数据 </template>
    </m-empty>
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :storageName="storageName"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :defaultCheckedList="defaultCheckedList"
    />
  </div>
</template>

<script>
import { getCpaDaysReport } from '@/apiForManage/report/cpaDays'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import ExportSvg from '@/assets/icons/export.svg?inline'
import RefreshSvg from '@/assets/icons/refresh.svg?inline'
import TagsSvg from '@/assets/icons/tags.svg?inline'
import excel from '@/utils/excel'
import request from '@/utils/request'
import { copy } from '@/utils/string'
import { numFormat } from '@/utils/dealNumber'
import localDataName from '@/utils/localDataNameForManage'
import { cpaDayColumns } from '../../reportColumns'
import CustTagsModal from '@/components/common/CustTagsModal'
import { allDimesion } from './dimesion'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import mixFetchPool from '@/mixins/fetchPool'
import Sortable from 'sortablejs'

export default {
  name: 'SynthTable',
  components: { CustTagsModal, IosSvg, AndroidSvg, ExportSvg, TagsSvg, RefreshSvg },
  mixins: [rateAndMoneyMethod, mixFetchPool],
  data () {
    return {
      defaultCheckedList: [], // 默认选中的指标
      targetArr: [], // 指标数组
      storageName: localDataName.cpaDaysReport, // 综合报表本地缓存key
      custTagsVisible: false, // 自定义指标弹窗是否显示
      sorterItem: null, // 当前排序属性
      searchquery: {
        groupByList: ['date'],
        page: 1,
        limit: 10
      },
      dataList: [], // 应用列表
      total: 0,
      isLoading: false,
      AngleShow: [], // 当前维度选择后，不显示的指标
      // 显示talbe（总table）
      columns: [],
      // 维度table
      baseColumns: [
        {
          title: '日期',
          dataIndex: 'date',
          width: 140,
          align: 'left',
          fixed: true
        }
      ],
      // 指标table
      sureColumns: [],
      sureArr: [],
      downLoading: false,
      allDimesion: JSON.parse(JSON.stringify(allDimesion)),
      isFirst: true
    }
  },
  mounted () {
    this.initSortable()
  },
  methods: {
    // 拖拽回调处理
    handelDimesionSort (newIndex, oldIndex) {
      const oldItem = this.allDimesion.splice(oldIndex, 1)
      this.allDimesion.splice(newIndex, 0, oldItem[0])
      this.$nextTick(() => {
        this.initSortable()
        this.changeCustDimension()
      })
    },
    // 维度拖拽排序
    initSortable () {
      const el = document.querySelector('.sort-checkbox-group')
      /* eslint-disable no-new */
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          this.handelDimesionSort(evt.newIndex, evt.oldIndex)
        }
      })
    },
    dealRouteQuery () {
      const { query = {} } = this.$route
      const { placeId, publisherId, dspId, hasPlat, hasSupplier, hasPlace } = query
      ;(hasPlace || placeId) && (this.searchquery.groupByList.push('place_id'))
      ;(hasSupplier || publisherId) && (this.searchquery.groupByList.push('publisher_id'))
      dspId && (this.searchquery.groupByList.push('dsp_place_id'))
      hasPlat && (this.searchquery.groupByList.push('plat_id'))
      this.changeCustDimension()
    },
    changeDimension (item) {
      if (this.searchquery.groupByList.includes(item.key)) {
        this.searchquery.groupByList = this.searchquery.groupByList.filter((i) => i !== item.key)
      } else {
        this.searchquery.groupByList.push(item.key)
      }
      this.changeCustDimension()
      this.sorterItem = null
      this.getColumns()
      this.getTableData()
    },
    // 修改自定义指标回调
    changeCustTags (targetArr, sureArr) {
      const sortItem = this.columns.find((item) => item.sortOrder)
      if (sortItem) {
        this.sorterItem = sortItem
      } else {
        this.sorterItem = null
      }
      this.sureArr = sureArr
      this.getColumns()
    },
    // 获取指标列表数组
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = cpaDayColumns
      this.defaultCheckedList = sureArr.map((item) => ({ ...item }))
      this.targetArr = targetArr
      if (!tagInfo) {
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
    },
    // 获取columns
    getColumns () {
      const arr = []
      this.sureArr.forEach((item) => {
        if (!this.AngleShow.includes(item.prop)) {
          arr.push({
            title: (
              <div>
                <m-tips showIcon={false} content={item.desc} title={item.label} width={150}></m-tips>
              </div>
            ),
            dataIndex: item.prop,
            width: null,
            align: 'left',
            sorter: item.sortable,
            sortDirections: ['descend', 'ascend'],
            // 判断之前的排序
            sortOrder: this.sorterItem && this.sorterItem.dataIndex === item.prop && this.sorterItem.sortOrder,
            scopedSlots: { customRender: item.prop }
          })
        }
      })
      this.sureColumns = arr
      this.sureColumns[0] && (this.sureColumns[0].width = null)
      this.columns = [...this.baseColumns, ...this.sureColumns]
    },
    // 排序处理
    handleSort (pagination, filters, sorter) {
      this.searchquery.sortBy = sorter.field
      this.searchquery.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
      // 修改columns
      this.columns.forEach((item) => {
        if (item.dataIndex === sorter.field) {
          item.sortOrder = sorter.order
          this.sorterItem = item
        } else {
          item.sortOrder = false
        }
      })
      this.getTableData()
    },
    // 可父级调用，处理query
    dealQuery (query) {
      // 可能是选择模版后搜索，放在这儿随时处理自定义指标
      this.initTargetArr()
      this.columns = [...this.baseColumns, ...this.sureColumns]
      this.searchquery.page = 1
      this.searchquery.sortBy = ''
      this.searchquery.sortOrder = ''
      this.sorterItem = null
      this.searchquery = { ...this.searchquery, ...query }
      if (this.isFirst) {
        this.isFirst = false
        this.dealRouteQuery()
      }
      // 若带有维度列表，则是模版搜索
      if (query.groupByList) {
        this.searchquery.groupByList = query.groupByList
        this.changeCustDimension()
      }
      this.getColumns()
      this.getTableData()
    },
    // 获取数据
    async getTableData () {
      this.getCancelToken('adx')
      this.isLoading = true
      const { data = {} } = await getCpaDaysReport(this.searchquery, this.cancelSource.token)
      const { total = 0, items = [], aggregate = {} } = data
      this.total = total
      aggregate.date = '汇总'
      this.dataList = [aggregate, ...items]
      this.dataList.length === 1 && (this.dataList = [])
      this.dataList.forEach((item) => {
        item.dau = numFormat(item.dau, 3, '')
        item.arpDeu = numFormat(item.arpDeu, 3, '')
        item.income = numFormat(item.income, 3, '')
        item.aipau = numFormat(item.aipau, 3, '')
        item.estimatedRevenueEcpm = numFormat(item.estimatedRevenueEcpm, 3, '')
        item.request = numFormat(item.request, 3, '')
        item.impress = numFormat(item.impress, 3, '')
        item.unitRevenue = numFormat(item.unitRevenue, 3, '')
        item.unitEcpm = numFormat(item.unitEcpm, 3, '')
        item.newLogin = numFormat(item.newLogin, 3, '')
        item.deu = numFormat(item.deu, 3, '')
        item.click = numFormat(item.click, 3, '')
        item.advRequest = numFormat(item.advRequest, 3, '')
        item.unitImpression = numFormat(item.unitImpression, 3, '')
        item.unitClick = numFormat(item.unitClick, 3, '')
        item.unitRequest = numFormat(item.unitRequest, 3, '')
        item.response = numFormat(item.response, 3, '')
        item.advPrice = numFormat(item.advPrice, 3, '')
      })
      await this.getShowArr()
      await this.getColumns()
      this.isLoading = false
      this.removeCancelSource('adx')
    },
    // 获取不显示得指标数组
    getShowArr () {
      // 不显示列数组
      this.AngleShow = []
      // 广告位
      let placearr = []
      if (this.searchquery.groupByList.includes('place_id') || this.searchquery.placeIdList.length > 0) {
        placearr = [
          'newLogin',
          'newUserRate',
          'askPriceRequest',
          'advRequestPrice'
        ]
      }
      //  广告源
      let sourcearr = []
      if (this.searchquery.groupByList.includes('dsp_place_id') || this.searchquery.dspPlaceIdList.length > 0) {
        sourcearr = [
          'dau',
          'permeability',
          'request',
          'response',
          'arpu',
          'arpDeu',
          'aipau',
          'newLogin',
          'newUserRate',
          'deu',
          'aipu',
          'requestFilledRate'
        ]
      }
      // 广告样式
      let positionarr = []
      if (this.searchquery.groupByList.includes('position_id') || this.searchquery.positionIdList.length > 0) {
        positionarr = [
          'newLogin',
          'newUserRate',
          'askPriceRequest',
          'advRequestPrice'
        ]
      }
      // 广告平台 账户
      let platarr = []
      if (
        this.searchquery.groupByList.includes('plat_id') ||
        this.searchquery.groupByList.includes('plat_account_id') ||
        this.searchquery.platIdList.length > 0
      ) {
        platarr = [
          'dau',
          'permeability',
          'request',
          'response',
          'arpu',
          'arpDeu',
          'aipau',
          'newLogin',
          'newUserRate',
          'deu',
          'aipu',
          'requestFilledRate',
          'awakenSuccessRate'
        ]
      }
      // 无维度
      let emptyarr = []
      if (this.searchquery.groupByList.length === 1) {
        emptyarr = ['askPriceRequest', 'advRequestPrice']
      }
      // 特殊处理广告收入和利润率
      let noSearchArr = []
      if (
        !this.searchquery.groupByList.includes('plat_id') &&
        !this.searchquery.groupByList.includes('plat_account_id') &&
        this.searchquery.platIdList.length === 0 &&
        !this.searchquery.groupByList.includes('dsp_place_id') &&
        this.searchquery.dspPlaceIdList.length === 0
      ) {
        noSearchArr = []
      }
      // 数组去重，取并集
      this.AngleShow = [
        ...new Set([
          ...placearr,
          ...sourcearr,
          ...positionarr,
          ...platarr,
          ...emptyarr,
          ...noSearchArr
        ])
      ]
    },
    // 修改维度后，获取数据
    changeCustDimension () {
      const arr = this.allDimesion.filter((item) => this.searchquery.groupByList.includes(item.key))
      // 当前维度列表
      const dimensions = arr.map((item) => {
        return {
          title: item.name,
          dataIndex: item.prop,
          width: item.width,
          align: 'left',
          fixed: !(arr.length >= 5),
          scopedSlots: { customRender: item.prop }
        }
      })
      this.baseColumns = [
        {
          title: '日期',
          dataIndex: 'date',
          width: 140,
          align: 'center',
          fixed: true
        },
        ...dimensions
      ]
      this.columns = [...this.baseColumns, ...this.sureColumns]
      // this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    },
    //   导出报表
    async exportReport () {
      this.downLoading = true
      const arr1 = this.sureArr.map((item) => {
        return { prop: item.prop, name: item.label }
      })
      const arr2 = []
      this.baseColumns.forEach((item) => {
        arr2.push({ prop: item.dataIndex, name: item.title })
      })
      const sumArr = [...arr2, ...arr1]
      const codeList = []
      const nameList = []
      sumArr.forEach((item) => {
        codeList.push(item.prop)
        nameList.push(item.name)
      })
      const query = JSON.parse(JSON.stringify(this.searchquery))
      query.codeList = codeList
      query.nameList = nameList
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `sheetjs.xlsx`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    },
    // 获取到出报表数据
    exportToExcel (query) {
      return request.post('/report/downloadCpaFullReport', query, {
        responseType: 'blob'
      })
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    }
  }
}
</script>

<style lang="less">
.synth-table-container {
  border-radius: @mediumRadius;
  background: #fff;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  padding: @smallMargin;
  margin-bottom: @mediumMargin;
  .control {
    padding-bottom: 14px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    .tag-btn{
      margin-right: @compMargin;
    }
    .left {
      display: flex;
      height: 36px;
      line-height: 36px;
      .dimension-btn{
        color: #344563;
        margin-right: 20px;
      }
      .dimension-icon{
        color: @assisColor;
        border: none;
        margin-right: 3px;
      }
      .dimension_list {
        display: flex;
        height: 100%;
        position: relative;
        padding-left: 20px;
        &::before{
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          width: 2px;
          height: 20px;
          background-color: #15223216;
          transform: translateY(30%);
        }
      }
    }
  }
  .table {
    display: flex;
    .synth_table {
      overflow: hidden;
      width: 100%;
      position: relative;
      .fixed_item {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ant-table-fixed-left table,
    .ant-table-fixed-right-right table {
      width: min-content;
    }
  }
  .pagination {
    padding-top: @smallMargin;
  }
}
.synth-popover {
  width: 240px;
  .synth-popover-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      // max-width: 240px;
      line-height: 30px;
    }
  }
}
</style>
