<template>
  <div class="budget-source-container">
    <div class="source-head">
      <div class="left">
        <m-select-more
          class="search-item"
          v-model="searchquery.platIdList"
          :allData="platList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :label="searchMap.plat.name"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :canSelectAll="true"
          :showLabel="true"
          @change="changeAccount"
          v-if="searchMap.plat.checked"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.platAccountIdList"
          :allData="platAccountList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :label="searchMap.account.name"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :showLabel="true"
          :canSelectAll="true"
          @change="handleSearch"
          v-if="searchMap.account.checked"
        />
        <m-select
          v-model="searchquery.area"
          :showSearch="false"
          :dataList="[
            { name: '国内', id: '0' },
            { name: '海外', id: '1' }
          ]"
          :label="searchMap.area.name"
          class="search-item"
          @change="handleSearch"
          v-if="searchMap.area.checked"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.platPositionNameList"
          :allData="positionNameList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :label="searchMap.positionName.name"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :showLabel="true"
          :canSelectAll="true"
          @change="handleSearch"
          v-if="searchMap.positionName.checked"
        />
        <m-select
          v-model="searchquery.sts"
          :showSearch="false"
          :dataList="[
            { name: '开启', id: 'A' },
            { name: '关闭', id: 'S' }
          ]"
          :label="searchMap.sts.name"
          class="search-item"
          @change="handleSearch"
          v-if="searchMap.sts.checked"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.supportOsList"
          :allData="osList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :label="searchMap.supportOs.name"
          :showSelectedPart="false"
          :hasSearch="false"
          width="220px"
          :showLabel="true"
          :canSelectAll="true"
          @change="handleSearch"
          v-if="searchMap.supportOs.checked"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.supportPositionList"
          :allData="positionList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :label="searchMap.position.name"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :showLabel="true"
          :canSelectAll="true"
          @change="handleSearch"
          v-if="searchMap.position.checked"
        />
        <m-select
          v-model="searchquery.frequency"
          :showSearch="false"
          :dataList="[
            { name: '是', id: 1 },
            { name: '否', id: 0 }
          ]"
          :label="searchMap.frequency.name"
          class="search-item"
          @change="handleSearch"
          v-if="searchMap.frequency.checked"
        />
        <a-input
          v-model.trim="searchquery.name"
          placeholder="请输入广告源/广告源ID"
          class="search-item"
          @change="changeSearchKey"
        ></a-input>
      </div>
      <div class="right">
        <m-icon-tips>
          <div class="group" slot="icon" @click="handleEditSearch">
            <GroupBtnSvg class="link-btn" />
          </div>
          <template slot="title">自定义筛选项</template>
        </m-icon-tips>
        <a-button type="primary" @click="handleEditSource(1)">添加广告源</a-button>
      </div>
    </div>
    <div class="main">
      <a-spin :spinning="isLoading">
        <el-table :data="dataList" style="width: 100%">
          <el-table-column prop="sts" label="状态" header-align="center" align="center">
            <template slot-scope="{ row }">
              <a-popconfirm placement="right" :title="`确认${row.sts === 'A' ? '关闭' : '开启'}该广告源?`" ok-text="是" cancel-text="否" @confirm="handleChangeSts(row)">
                <a-switch size="small" :checked="row.sts === 'A'"></a-switch>
              </a-popconfirm>
            </template>
          </el-table-column>
          <!-- 只有平台运营和超管展示所属账户 -->
          <el-table-column prop="userName" label="所属账户" min-width="120" v-if="+roleId === 4 || role === 'SUPER_ADMIN'">
            <template slot-scope="{row}">
              <div class="manager-text text-ellipsis" :title="row.name">
                {{ row.userName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="广告源名称/ID" min-width="171">
            <div slot-scope="{ row }" class="name-row" style="width: 150px">
              <div class="name text-ellipsis" :title="row.name">{{ row.name }}
                <m-tips
                  v-if="+row.sourceType"
                  :content="`平台为所有用户提供预算资源。您只需将平台预算分配给您的供应商，即可开始使用。请注意，平台按月结算费用，在结算前需与平台签订合同。`"
                  title=""
                  :width="200"
                ></m-tips>
              </div>
              <div class="id">
                <CopySvg class="copy-icon" @click="copyId(row.platPlaceId)" />
                <div class="value text-ellipsis">{{ row.platPlaceId }}</div>
              </div>
            </div>
          </el-table-column>
          <el-table-column prop="platName" label="广告平台" min-width="100"> </el-table-column>
          <el-table-column prop="platAccountName" label="账号名称" min-width="138"> </el-table-column>
          <el-table-column prop="positionName" label="广告样式" min-width="85"> </el-table-column>
          <el-table-column prop="supportPositionNameList" label="支持系统类型" min-width="120">
            <template slot-scope="{ row }">
              {{ (row.supportOsList || []).map((item) => (+item === 1 ? 'Android' : 'IOS')).join('/') }}
            </template>
          </el-table-column>
          <el-table-column prop="supportPositionNameList" label="支持流量类型" min-width="172">
            <div class="support-position-name-list" slot-scope="{ row }">
              <template v-if="row.supportPositionNameList && row.supportPositionNameList.length > 0">
                {{ (row.supportPositionNameList || []).join('/') }}
              </template>
              <template v-else>
                {{ row.positionName }}
              </template>
            </div>
          </el-table-column>
          <!-- 只有平台运营展示合作状态 -->
          <el-table-column prop="cooperationSts" label="合作状态" min-width="80" v-if="+roleId === 4">
            <template slot-scope="{ row }">
              {{ row.cooperationSts === 'A' ? '合作中' : '暂停' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="80" class-name="action-column">
            <template slot-scope="{ row }">
              <a-popover placement="bottom" :ref="`popover${row.id}`" overlayClassName="dsp_setting_popover">
                <GroupSvg style="cursor: pointer;color:#fff;" />
                <div class="popover-btn-box" slot="content">
                  <div class="popover-btn" @click="handleSkipToReport(row)"><WatchDataSvg class="icon"/>查看数据</div>
                  <div class="popover-btn" @click="handleSkipToConfig(row)"><SettingDetailSvg class="icon"/>配置详情</div>
                  <div class="popover-btn" @click="handleFrequency(row)"><ContralSvg class="icon" v-if="+row.frequency === 1"/><SlidersSvg class="icon" v-else/>频次控制</div>
                  <template v-if="!+row.sourceType">
                    <div class="popover-btn" @click="handleEditSource(2, row)"><EditSvg class="icon"/>编辑</div>
                    <div class="popover-btn" @click="handleClickDeleteBtn(row)"><DeleteSvg class="icon"/>删除</div>
                  </template>
                </div>
              </a-popover>
              <!-- <span class="link-btn primary-text" @click="handleSkipToReport(row)">查看数据</span>
              <span class="link-btn primary-text" @click="handleSkipToConfig(row)">配置详情</span>
              <m-icon-tips>
                <ContralSvg class="link-btn" v-if="+row.frequency === 1" @click="handleFrequency(row)" slot="icon" />
                <SlidersSvg class="link-btn" v-else @click="handleFrequency(row)" slot="icon" />
                <template slot="title">频次控制</template>
              </m-icon-tips>
              <template v-if="!+row.sourceType">
                <m-icon-tips>
                  <EditSvg class="link-btn" @click="handleEditSource(2, row)" slot="icon" />
                  <template slot="title">编辑</template>
                </m-icon-tips>
                <m-icon-tips>
                  <DeleteSvg class="link-btn" @click="handleClickDeleteBtn(row)" slot="icon" />
                  <template slot="title">删除</template>
                </m-icon-tips>
              </template> -->
            </template>
          </el-table-column>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="dataList.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
        <m-empty v-else style="height: 341px" imgHeight="176px">
          <template #description>暂无数据 </template>
        </m-empty>
      </a-spin>
    </div>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="广告源"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
    <!-- 广告源弹窗 -->
    <SourceModal
      :brandList="brandList"
      :countryList="countryList"
      :installAppList="installAppList"
      v-if="editSourceVisible"
      :visible="editSourceVisible"
      :sourceInfo="isAdd === 1 ? undefined : sourceInfo"
      @modalCancel="editSourceVisible = false"
      :isAdd="isAdd"
      @changeSource="getDataList"
    />
    <!-- 频次控制 -->
    <FrequencyModal
      v-if="frequencyVisible"
      :visible="frequencyVisible"
      :sourceInfo="sourceInfo"
      @modalCancel="frequencyVisible = false"
      @changeSource="getDataList"
      :query="searchquery"
      :platList="platList"
    />
    <!-- 自定义筛选项 -->
    <CheckSearchModal
      :visible="checkSearchVisible"
      @modalCancel="checkSearchVisible = false"
      :searchMap="searchMap"
      @handleChangeChecked="handleChangeChecked"
    />
  </div>
</template>

<script>
import { dspSourceReport, updateDspSts } from '@/apiForManage/aggregate'
import { copy } from '@/utils/string'
import GroupBtnSvg from '@/assets/icons/group.svg?inline'
import CopySvg from '@/assets/icons/copy.svg?inline'
import ContralSvg from '@/assets/icons/contral.svg?inline'
import SlidersSvg from '@/assets/icons/sliders.svg?inline'
import mixGetList from '@/mixins/getListForManage'
import FrequencyModal from './components/frequencyModal'
import { getBrandList, getRtbPlatAccounts, getDirectionalSettingAppList, getCountryList } from '@/apiForManage/common'
import SourceModal from './components/sourceModal'
import CheckSearchModal from './components/CheckSearchModal'
import { searchMap } from './searchMap'
import { getSearchItem } from '@/apiForManage/user'
import { mapState } from 'vuex'
import actionIconMixin from '@/mixins/actionIcon'
export default {
  components: { CopySvg, SourceModal, FrequencyModal, ContralSvg, SlidersSvg, GroupBtnSvg, CheckSearchModal },
  mixins: [mixGetList, actionIconMixin],
  data () {
    return {
      searchMap: JSON.parse(JSON.stringify(searchMap)),
      checkSearchVisible: false,
      frequencyVisible: false,
      delVisible: false,
      editSourceVisible: false,
      isAdd: true,
      sourceInfo: {},
      searchquery: {
        area: undefined,
        platIdList: [],
        platAccountIdList: [],
        name: undefined,
        platPositionNameList: [],
        supportPositionList: [],
        supportOsList: [],
        frequency: undefined,
        sts: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      dataList: [],
      isLoading: false,
      // platList: [],
      platAccountList: [],
      timer: undefined,
      brandList: [],
      countryList: [],
      installAppList: []
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    })
  },
  async created () {
    await this.dealSearchItem()
    await this.getPlatListByuser()
    this.getBrandList()
    this.getCountryList()
    this.getDirectionalSettingAppList()
    this.getPlatPositionList()
    await this.getRtbPlatAccounts()
    this.initRouterQuery()
    this.getDataList()
    this.getPositionList()
  },
  methods: {
    async dealSearchItem () {
      const { data = [] } = await getSearchItem({
        menuKey: 'dspSource'
      })
      const { selectInfoList = [] } = data
      if (!selectInfoList.length) {
        return
      }
      for (const key in this.searchMap) {
        if (Object.hasOwnProperty.call(this.searchMap, key)) {
          const element = this.searchMap[key]
          element.checked = selectInfoList.includes(element.prop)
        }
      }
    },
    handleChangeChecked (query) {
      this.searchMap = query
      const list = Object.values(this.searchMap).filter((item) => !item.checked)
      list.forEach((item) => {
        this.searchquery[item.prop] = item.default
      })
      this.getDataList()
    },
    handleEditSearch () {
      this.checkSearchVisible = true
    },
    async changeAccount () {
      await this.getRtbPlatAccounts()
      this.getDataList()
    },
    initRouterQuery () {
      const { query = {}, params = {} } = this.$route
      const { dspId, accountId } = query
      this.searchquery.dspId = dspId
      accountId && (this.searchquery.platAccountIdList = [accountId])
      params.page && (this.searchquery = { ...this.searchquery, ...params })
      this.$router.push({
        query: {}
      })
    },
    handleSkipToReport (row) {
      const url = this.$router.resolve({
        name: 'synthreport',
        query: {
          dspId: '' + row.id
        }
      })
      window.open(url.href, '_blank')
    },
    // 安装列表
    async getDirectionalSettingAppList () {
      const { data = [] } = await getDirectionalSettingAppList()
      this.installAppList = data
    },
    async getCountryList () {
      const { data = [] } = await getCountryList()
      data.forEach((item) => {
        item.id = item.code
        item.name = item.nameCn
      })
      this.countryList = Object.freeze(data)
    },
    // 获取品牌
    async getBrandList () {
      const { data = [] } = await getBrandList()
      data.forEach((item) => {
        item.id = item.name
      })
      this.brandList = Object.freeze(data)
    },
    changeSearchKey (e) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = undefined
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 1000)
    },
    // 获取所有广告平台
    // async getPlatList (hasPlat = false) {
    //   const { data = [] } = await getRtbPlatformList()
    //   data.forEach((item) => {
    //     item.id = '' + item.id
    //   })
    //   this.platList = data
    //   await this.getRtbPlatAccounts()
    // },
    // 获取广告平台账户下拉
    async getRtbPlatAccounts () {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: this.searchquery.platIdList
      })
      this.platAccountList = data
    },
    handleEditSource (isAdd, row) {
      this.isAdd = isAdd
      if (isAdd === 2) {
        this.$refs[`popover${row.id}`].$children[0].sVisible = false
        this.sourceInfo = JSON.parse(JSON.stringify(row))
      }
      this.editSourceVisible = true
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await dspSourceReport(this.searchquery)
      const { items = [], total = 0 } = data
      this.dataList = items
      this.total = total
      this.isLoading = false
      this.searchquery.dspId = undefined
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleChangeSts (row) {
      updateDspSts({
        ...row,
        sts: row.sts === 'A' ? 'S' : 'A'
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success('修改成功')
          this.getDataList()
        }
      })
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    },
    handleClickDeleteBtn (row) {
      this.delVisible = true
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.sourceInfo = JSON.parse(JSON.stringify(row))
    },
    handleFrequency (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.frequencyVisible = true
      this.sourceInfo = JSON.parse(JSON.stringify(row))
    },
    handleDeleteItem () {
      updateDspSts({
        ...this.sourceInfo,
        id: this.sourceInfo.id,
        sts: 'D'
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success('删除成功')
          this.delVisible = false
          this.handleSearch()
        }
      })
    },
    handleSkipToConfig (row) {
      this.$router.push({
        name: 'dspconfiguration',
        query: {
          id: row.id,
          dspName: row.name,
          platAccountName: row.platAccountName,
          platName: row.platName,
          settleRatio: row.settleRatio,
          platPlaceId: row.platPlaceId,
          sourceType: row.sourceType
        },
        params: {
          ...this.searchquery
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.budget-source-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .source-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      flex: 1 auto;
      .search-item {
        margin-right: @compMargin;
        width: 180px;
        margin-bottom: 6px;
      }
    }
    .right {
      width: 220px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .group {
        height: 100%;
        background-color: #fff;
        padding: 6px 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: @compMargin;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid rgba(21, 34, 50, 0.08);
        cursor: pointer;
        .link-btn {
          color: #fff;
        }
      }
      button {
        width: 130px;
      }
    }
  }
  .main {
    background-color: #fff;
    padding: @smallMargin;
    margin-top: calc(@mediumMargin - 6px);
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    border-radius: @mediumRadius;
    padding-top: calc(@smallMargin - @thPadding);
    .support-position-name-list {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .name-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      .id,
      .name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .id {
        color: #858fa1;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .value{
        flex: 1 auto;
      }
      .copy-icon {
        cursor: pointer;
        min-width: 13px;
        min-height: 13px;
        margin-right: 3px;
      }
    }
    .link-btn {
      margin-right: 12px;
      cursor: pointer;
    }
    .pagination {
      margin-top: @smallMargin;
    }
  }
}
.popover-btn-box{
  display: flex;
  flex-direction: column;
  .popover-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
    .icon{
      margin-right: 6px;
      color: white;
    }
  }
}
</style>

<style lang="less">
.dsp_setting_popover{
  .ant-popover-inner-content{
    padding: 6px 12px;
  }
}
</style>
