<template>
  <div class="cost-table-container">
    <div class="control">
      <div class="left">
        <span
          @click="Visible = true"
          class="dimension-btn"
        ><a-icon class="dimension-icon" type="radar-chart" />维度</span
        >
        <div class="dimension_list sort-checkbox-group">
          <a-checkbox
            class="checkbox-item"
            @click="changeDimension(item)"
            v-for="item in allDimesion"
            :key="item.prop"
            :checked="query.groupByList.includes(item.key)"
          >{{ item.label }}</a-checkbox
          >
        </div>
      </div>
      <div class="right">
        <m-icon-tips>
          <div class="group" slot="icon" @click="exportCostReport">
            <ExportSvg class="link-btn" />
          </div>
          <template slot="title">导出</template>
        </m-icon-tips>
      </div>
    </div>
    <a-table
      class="m_table_cust"
      size="middle"
      :rowKey="(record, index) => index"
      :data-source="dataList"
      :scroll="{ x: true }"
      :pagination="false"
      :loading="isLoading"
    >
      <a-table-column v-for="item in compColumn" :key="item.dataIndex" :dataIndex="item.dataIndex">
        <span slot="title">
          <m-tips
            v-if="item.scopedSlots.title"
            :key="item.dataIndex"
            :content="item.desc"
            :title="item.title"
            :width="150"
          ></m-tips>
          <span v-else>{{ item.title }}</span>
        </span>
        <template slot-scope="text, record">
          <span v-if="item.isDimension">{{ record[item.dataIndex] }}</span>
          <span v-else :key="item.dataIndex">{{ isMoney(item.dataIndex) ? moneyText : '' }}{{ numFormat(record[item.dataIndex], 3, '') }}</span>
        </template>
      </a-table-column>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 1"
      show-size-changer
      :current="query.page"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
      :pageSize="query.limit"
    />
    <m-empty v-if="dataList.length <= 1" style="height: 341px" imgHeight="176px">
      <template #description>暂时没有任何数据</template>
    </m-empty>
  </div>
</template>

<script>
import { getCostTable } from '@/apiForManage/report/cost'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import excel from '@/utils/excel'
import { allDimesion, dateArr, targets } from './list'
import request from '@/utils/request'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import TagsSvg from '@/assets/icons/tags.svg?inline'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import mixFetchPool from '@/mixins/fetchPool'
import ExportSvg from '@/assets/icons/export.svg?inline'
import Sortable from 'sortablejs'
export default {
  name: 'CostTable',
  components: { IosSvg, AndroidSvg, TagsSvg, ExportSvg },
  mixins: [rateAndMoneyMethod, mixFetchPool],
  data () {
    return {
      dataList: [],
      isLoading: false,
      latitudeArr: [],
      exportLoading: false,
      allDimesion,
      // 日期 column
      dateArr,
      total: 0,
      query: {
        groupByList: ['date'],
        limit: 10,
        page: 1
      },
      targets
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    }),
    compColumn () {
      // 日期 维度 指标
      return [...this.dateArr, ...this.latitudeArr, ...this.targets]
    }
  },
  mounted () {
    this.initSortable()
  },
  methods: {
    // 拖拽回调处理
    handelDimesionSort (newIndex, oldIndex) {
      const oldItem = this.allDimesion.splice(oldIndex, 1)
      this.allDimesion.splice(newIndex, 0, oldItem[0])
      this.$nextTick(() => {
        this.initSortable()
        this.changeCustDimension()
      })
    },
    // 维度拖拽排序
    initSortable () {
      const el = document.querySelector('.sort-checkbox-group')
      /* eslint-disable no-new */
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          this.handelDimesionSort(evt.newIndex, evt.oldIndex)
        }
      })
    },
    changeDimension (item) {
      if (this.isLoading) {
        return
      }
      if (this.query.groupByList.includes(item.key)) {
        this.query.groupByList = this.query.groupByList.filter((i) => i !== item.key)
      } else {
        this.query.groupByList.push(item.key)
      }
      this.changeCustDimension()
      this.query.page = 1
      this.getTableData()
    },
    numFormat,
    handleSearch (query) {
      this.query = {
        ...this.query,
        ...query,
        page: 1
      }
      this.changeCustDimension()
      this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableData()
    },
    // 选择维度处理
    changeCustDimension () {
      this.latitudeArr = this.allDimesion
        .filter((item) => this.query.groupByList.includes(item.key))
        .map((item) => ({
          key: item.key,
          title: item.label,
          dataIndex: item.prop,
          name: item.label,
          width: item.width,
          align: 'left',
          scopedSlots: { customRender: item.prop },
          ellipsis: !item.isWrap,
          isDimension: true
        }))
    },
    // 获取列表
    async getTableData () {
      this.isLoading = true
      const { code, data = {} } = await getCostTable(this.query)
      if (code === 200 && data) {
        const { items = [], aggregate = {}, total = 0 } = data
        aggregate.date = '汇总'
        this.total = total
        this.dataList = items.length > 0 ? [aggregate, ...items] : []
      }
      this.isLoading = false
    },
    // 导出表格接口函数
    async downloadReport (query) {
      return request.post('report/downloadFeeReport', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportCostReport () {
      if (this.exportLoading) return
      this.exportLoading = true
      const codesList = []
      const namesList = []
      this.compColumn.forEach((item) => {
        namesList.push(item.label || item.title)
        codesList.push(item.prop || item.dataIndex)
      })
      const parmars = {
        ...this.query,
        codeList: codesList,
        nameList: namesList
      }
      try {
        const res = await this.downloadReport(parmars)
        await excel(res, `消耗报表.xls`)
        this.exportLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cost-table-container {
  border-radius: @mediumRadius;
  background: #fff;
  padding: @smallMargin;
  margin-top: @mediumMargin;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  .control {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    border-bottom: 1px solid #eee;
    .dimension-btn {
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px;
      .dimension-icon {
        color: @assisColor;
        font-size: 15px;
        margin-right: 10px;
      }
    }
    .left {
      display: flex;
      height: 36px;
      line-height: 36px;
      .dimension_list {
        display: flex;
        height: 100%;
        position: relative;
        padding-left: 20px;
        &::before {
          content: '';
          position: absolute;
          left: 0px;
          width: 1px;
          height: 22px;
          background-color: #ccc;
          transform: translateY(30%);
        }
        .checkbox-item {
          margin: 0 10px;
        }
      }
    }
    .right {
      display: flex;
      .group {
        height: 100%;
        background-color: #fff;
        padding: 6px 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: @compMargin;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid rgba(21, 34, 50, 0.08);
        cursor: pointer;
        .link-btn {
          color: #fff;
        }
      }
    }
    button {
      padding: 0 0 10px 0;
    }
  }
  .pagination {
    padding: 10px 10px;
  }
}
::v-deep {
  .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
</style>
