/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:59:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-31 14:16:44
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/HoursReport/HourTable/list.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const dateArr = [
  {
    title: '日期',
    prop: 'date',
    width: 130,
    align: 'left',
    label: '日期',
    scopedSlots: { customRender: 'date' },
    fixed: true
  }
]

export const allDimesion = [
  {
    key: 'publisher_id',
    prop: 'publisherName',
    isWrap: false,
    label: '供应商',
    checked: false,
    width: 120
  },
  {
    key: 'place_id',
    prop: 'placeName',
    isWrap: false,
    label: '广告位',
    checked: false,
    width: 180
  },
  {
    key: 'position_id',
    prop: 'positionName',
    isWrap: false,
    label: '广告样式',
    checked: false,
    width: 100
  },
  {
    key: 'plat_id',
    prop: 'platName',
    isWrap: false,
    label: '广告平台',
    checked: false,
    width: 180
  },
  {
    key: 'plat_account_id',
    prop: 'platAccountName',
    isWrap: false,
    label: '广告平台账户',
    checked: false,
    width: 180
  },
  {
    key: 'dsp_place_id',
    prop: 'dspPlaceName',
    isWrap: false,
    label: '广告源',
    checked: false,
    width: 180
  },
  {
    key: 'os',
    prop: 'os',
    isWrap: false,
    label: '操作系统',
    checked: false,
    width: 80
  },
  {
    key: 'country_code',
    prop: 'countryName',
    isWrap: false,
    label: '地区',
    checked: false,
    width: 150
  },
  {
    key: 'bundle',
    prop: 'bundle',
    isWrap: false,
    label: '应用包名',
    checked: false,
    width: 180
  },
  {
    key: 'traffic_place_id',
    isWrap: false,
    label: '流量广告位ID',
    checked: false,
    prop: 'trafficPlaceId',
    width: 180
  },
  {
    key: 'asi',
    isWrap: false,
    label: '来源',
    checked: false,
    prop: 'asi',
    width: 120
  }
]
