<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-18 14:29:03
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-19 14:27:53
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/FlowStrategy/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="flow-strategy-container">
    <div class="search-container">
      <div class="left">
        <m-select-more
          class="search-item"
          v-model="searchquery.publisherIdList"
          :allData="supplierList"
          :searchById="true"
          :hasIcon="true"
          :showId="true"
          width="450px"
          label="供应商"
          @change="changeSupplier"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.placeIdList"
          :allData="placeList"
          :searchById="true"
          :hasIcon="true"
          :showId="true"
          width="450px"
          label="广告位"
          @change="handleSearch"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.platIdList"
          :allData="platList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :hasSearch="true"
          label="广告平台"
          :showSelectedPart="true"
          width="450px"
          :canSelectAll="true"
          @change="handleSearch"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.dspPlaceIdList"
          :allData="dspList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          label="广告源"
          :showSelectedPart="true"
          width="450px"
          :canSelectAll="true"
          @change="handleSearch"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.strategyTypeList"
          :allData="strategyTypeList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :hasSearch="false"
          label="策略类型"
          :showSelectedPart="false"
          width="220px"
          :canSelectAll="false"
          @change="handleSearch"
        />
        <m-select
          v-model="searchquery.sts"
          :showSearch="false"
          :dataList="[
            { name: '开启', id: 'A' },
            { name: '关闭', id: 'S' }
          ]"
          label="状态"
          class="search-item"
          @change="handleSearch"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.modeList"
          :allData="[
            { name: '手动', id: '1' },
            { name: '自动', id: '2' },
            { name: '点击率控制', id: '3' }
          ]"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          :hasSearch="false"
          label="控制方式"
          :showSelectedPart="false"
          width="220px"
          :canSelectAll="false"
          @change="handleSearch"
        />
        <!-- <m-select
          v-model="searchquery.modeList"
          :showSearch="false"
          :dataList="[
            { name: '手动', id: 1 },
            { name: '自动', id: 2 },
            { name: '点击率控制', id: 3 }
          ]"
          label="控制方式"
          class="search-item"
          @change="handleSearch"
        /> -->
      </div>
      <div class="right">
        <a-button type="primary" @click="handleAddStrategy">新建QPS</a-button>
      </div>
    </div>
    <div class="table-container">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="sts" label="状态" min-width="60">
            <template slot-scope="{ row }">
              <a-switch size="small" :checked="row.sts === 'A'" @click="handleChangeSts(row)"></a-switch>
            </template>
          </el-table-column>
          <el-table-column prop="dspPlaceName" label="广告源" min-width="160">
            <div class="name-id-box" style="width: 150px;" slot-scope="{ row }">
              <div class="name text-ellipsis" :title="row.dspPlaceName">{{ row.dspPlaceName }}</div>
              <div class="id">
                <div class="id-box">{{ row.platPlaceId }}</div>
                <CopySvg class="copy-icon percent-text" @click="copyId(row.platPlaceId)" />
              </div>
            </div>
          </el-table-column>
          <el-table-column prop="publisherName" label="供应商" min-width="160">
            <div class="name-id-box" slot-scope="{ row }">
              <template v-if="+row.strategyType === 1 || +row.strategyType === 2">
                <div class="name text-ellipsis" :title="row.publisherName">{{ row.publisherName }}</div>
                <div class="id">
                  <div class="id-box">{{ row.publisherId }}</div>
                  <CopySvg class="copy-icon percent-text" @click="copyId(row.publisherId)" />
                </div>
              </template>
              <span v-else>--</span>
            </div>
          </el-table-column>
          <el-table-column prop="placeName" label="广告位" min-width="160">
            <div class="name-id-box" slot-scope="{ row }">
              <template v-if="+row.strategyType === 2">
                <div class="name text-ellipsis" :title="row.placeName">{{ row.placeName }}</div>
                <div class="id">
                  <div class="id-box">{{ row.placeId }}</div>
                  <CopySvg class="copy-icon percent-text" @click="copyId(row.placeId)" />
                </div>
              </template>
              <span v-else>--</span>
            </div>
          </el-table-column>
          <el-table-column prop="strategyType" label="策略类型" min-width="120">
            <template slot-scope="{ row }">
              {{ getStrategyTypeName(row.strategyType) }}
            </template>
          </el-table-column>
          <el-table-column prop="qpsLimit" label="QPS" min-width="70">
            <template slot-scope="{ row }">
              <span v-if="row.qpsLimit">{{ row.qpsLimit }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="requestRatio" label="请求比例" min-width="90">
            <template slot-scope="{ row }">
              <span v-if="row.requestRatio">{{ row.requestRatio || 0 }}%</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="mode" label="控制方式" min-width="80">
            <template slot-scope="{ row }">
              <span >{{ +row.mode === 1 ? '手动' : +row.mode === 2 ? '自动' : +row.mode === 3 ? '点击率控制' : '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="180">
          </el-table-column>
          <el-table-column prop="updateTime" label="修改时间" min-width="180">
          </el-table-column>
          <!-- <el-table-column prop="requestRatio" label="状态" min-width="120">
            <template slot-scope="{ row }">
              <m-sts :sts="row.sts === 'A'" />
            </template>
          </el-table-column> -->
          <el-table-column label="操作" min-width="80" class-name="action-column">
            <template slot-scope="{ row }">
              <a-popover placement="bottom" :ref="`popover${row.id}`" overlayClassName="qps_setting_popover">
                <GroupSvg style="cursor: pointer;color:#fff;" />
                <div class="popover-btn-box" slot="content">
                  <div class="popover-btn" @click="handleEdit(row)"><EditSvg class="icon"/>编辑</div>
                  <div class="popover-btn" @click="handleDelete(row)"><DeleteSvg class="icon"/>删除</div>
                </div>
              </a-popover>
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
    <FlowStrategyModel
      :visible="editFlowStrategyVisable"
      :strategyInfo="strategyInfo"
      :isAdd="isAdd"
      @modalCancel="editFlowStrategyVisable = false"
      @changeStrategy="getDataList"
    />
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="QPS策略"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
  </div>
</template>

<script>
import MixGetList from '@/mixins/getListForManage'
import { getFlowStrategyPage, deleteFlowStrategy, updateFlowStrategy } from '@/apiForManage/functionList/flowStrategy'
import { strategyTypeList } from './list'
import { copy } from '@/utils/string'
import CopySvg from '@/assets/icons/copy.svg?inline'
import FlowStrategyModel from './conponents/EditFlowStrategyModal'
import actionIconMixin from '@/mixins/actionIcon'
export default {
  name: 'FlowStrategy',
  components: { CopySvg, FlowStrategyModel },
  mixins: [MixGetList, actionIconMixin],
  data () {
    return {
      strategyTypeList,
      searchquery: {
        publisherIdList: [],
        placeIdList: [],
        dspPlaceIdList: [],
        strategyTypeList: [],
        platIdList: [],
        modeList: [],
        sts: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      isLoading: false,
      strategyInfo: {},
      editFlowStrategyVisable: false,
      isAdd: false,
      delVisible: false
    }
  },
  async created () {
    await this.getSupplierList()
    await this.getPlaceList()
    await this.getDspList()
    await this.getPlatList()
    this.getDataList()
  },
  methods: {
    async handleChangeSts (row) {
      const resp = await updateFlowStrategy({
        ...row,
        sts: row.sts === 'A' ? 'S' : 'A'
      })
      if (resp.code === 200) {
        this.$message.success('操作成功')
        this.getDataList()
      }
    },
    handleDeleteItem () {
      deleteFlowStrategy({
        id: this.strategyInfo.id
      }).then((resp) => {
        if (resp.code === 200) {
          this.delVisible = false
          this.$message.success('删除成功')
          this.searchquery.page = 1
          this.getDataList()
        }
      })
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleEdit (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.editFlowStrategyVisable = true
      this.strategyInfo = row
      this.isAdd = false
    },
    handleDelete (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.strategyInfo = row
      this.delVisible = true
    },
    getStrategyTypeName (id) {
      return this.strategyTypeList.find((item) => +item.id === +id)?.name || '--'
    },
    handleAddStrategy () {
      this.isAdd = true
      this.editFlowStrategyVisable = true
    },
    async changeSupplier (ids) {
      await this.getPlaceList(ids)
      this.handleSearch()
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    // 获取数据列表
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getFlowStrategyPage(this.searchquery)
      const { total = 0, items = [] } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
@compBottom: 10px;
.flow-strategy-container {
  padding: 0 @mediumMargin;
  .search-container {
    // padding: @smallMargin;
    display: flex;
    justify-content: space-between;
    .left {
      .search-item {
        margin-right: @compMargin;
        width: 180px;
        margin-bottom: @compBottom;
      }
    }
    .right {
      button {
        width: 146px;
      }
    }
  }
  .table-container {
    margin-top: calc(@mediumMargin - @compBottom);
    border-radius: @mediumRadius;
    background-color: #fff;
    padding: 0 @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    .name-id-box {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .name {
        width: 100%;
        color: @compValueColor;
        font-weight: 400;
      }
      .id {
        display: flex;
        width: 100%;
        color: @compDisabledColor;
        align-items: center;
        position: relative;
        .id-box {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: calc(100% - 20px);
        }
        .copy-icon {
          height: 15px;
          width: 15px;
          cursor: pointer;
        }
      }
    }
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
.popover-btn-box{
  display: flex;
  flex-direction: column;
  .popover-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
    .icon{
      margin-right: 6px;
      color: white;
    }
  }
}
</style>

<style lang="less">
.qps_setting_popover{
  .ant-popover-inner-content{
    padding: 6px 12px;
  }
}
</style>
